import React from 'react'

import { TreeProps } from './types.d'

import TreeItem from './TreeItem'

const Tree: React.FC<TreeProps> = ({
  before = [],
  current,
  highlightedItems = [],
  highlightedIdKey = 'id',
  amenityTypeFilter,
  unitFieldOption,
  amenityFieldOption,
  selectable,
  checkCallback,
  readOnly,
  fromModal,
  showStatus,
  showTickets,
  checkboxComponent
}) => {
  return (
    <>
      {before.map((item, key) =>
        <TreeItem
          key                = {`${item.class_plural}-${item.id}`}
          item               = {item}
          before             = {before}
          highlightedItems   = {highlightedItems}
          highlighted        = {!!highlightedItems.find(i => item.id === i[highlightedIdKey] && item.class_plural === i.class_plural)}
          highlightedIdKey   = {highlightedIdKey}
          indent             = {key + 1}
          readOnly           = {readOnly}
          fromModal          = {fromModal}
          selectable         = {selectable}
          selectCallback     = {checkCallback}
          showStatus         = {showStatus}
          showTickets        = {showTickets}
          amenityTypeFilter  = {amenityTypeFilter}
          unitFieldOption    = {unitFieldOption}
          amenityFieldOption = {amenityFieldOption}
          isFolded           = {!highlightedItems.find(i => item.id === i[highlightedIdKey] && item.class_plural === i.class_plural)}
          checkboxComponent  = {checkboxComponent}
        />
      )}
      {current &&
        <TreeItem
          item               = {current}
          highlightedItems   = {highlightedItems}
          highlighted        = {!!(highlightedItems.length && highlightedItems.find(i => current.id === i[highlightedIdKey] && current.class_plural === i.class_plural))}
          highlightedIdKey   = {highlightedIdKey}
          before             = {before}
          indent             = {before.length + 1}
          readOnly           = {readOnly}
          fromModal          = {fromModal}
          selectable         = {selectable}
          selectCallback     = {checkCallback}
          showStatus         = {showStatus}
          showTickets        = {showTickets}
          amenityTypeFilter  = {amenityTypeFilter}
          unitFieldOption    = {unitFieldOption}
          amenityFieldOption = {amenityFieldOption}
          isFolded           = {!highlightedItems.find(i => current.id === i[highlightedIdKey] && current.class_plural === i.class_plural)}
          checkboxComponent  = {checkboxComponent}
        />
      }
    </>
  )
}

export default Tree
