import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button              from '@components/Button'
import Callout             from '@components/Callout'
import Checkbox            from '@form/Checkbox'
import DatePicker          from '@form/DatePicker'
import FieldInput          from '@form/FieldInput'
import FileUploader        from '@components/Form/FileUploader'
import Form                from '@components/Form/Form'
import Input               from '@form/Input'
import Select              from '@form/Select'
import SelectDoerForm      from '@components/User/SelectDoerForm'
import SelectEquipmentForm from '@components/Amenity/SelectEquipmentForm'
import Tag                 from '@components/Tag'
import Textarea            from '@form/Textarea'

import comburantImg    from '@pages/Reports/Show/images/comburant.png'
import corrosiveImg    from '@pages/Reports/Show/images/corrosive.png'
import environementImg from '@pages/Reports/Show/images/environement.png'
import explosionImg    from '@pages/Reports/Show/images/explosion.png'
import fuelImg         from '@pages/Reports/Show/images/fuel.png'
import healthImg       from '@pages/Reports/Show/images/health.png'
import nociveImg       from '@pages/Reports/Show/images/nocive.png'
import pressureImg     from '@pages/Reports/Show/images/pressure.png'
import toxicImg        from '@pages/Reports/Show/images/toxic.png'

import CustomTypeOption from './CustomTypeOption'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import IReport from '@interfaces/IReport.d'

import { buildFilterString } from '@utils/filterString'

import { REPORT_ACTIONS, reportReducer } from '@reducers/index'
import SelectTree from '@components/Form/SelectTree'

interface ReportFormProps {
  report?:       IReport,
  updateMethods: object
}

const ReportForm: React.FC<ReportFormProps> = ({
  report,
  updateMethods
}) => {

  const {
    i18n,
    fetchApi,
    current_user,
    current_company,
    closeModal,
    setInfoWindowProps,
    setModalInfoWindowProps,
    CONSTANTS,
    isDesktop
  } = useGlobalContextState()

  const [_state, dispatch] = useReducer(reportReducer, { report, fetchApi, updateMethods, closeModal, setInfoWindowProps })

  const { REPORT }      = CONSTANTS
  const { ROLES }       = CONSTANTS.USER
  const { TYPE_VALUES } = CONSTANTS.REPORT_TYPES

  // const [view,                setView]       = useState(SELECT)
  const [reportTypes,         setReportTypes]         = useState(current_company.report_types)
  const [selfAssign,          setSelfAssign]          = useState(false)
  const [expectedDate,        setExpectedDate]        = useState(report?.expected_date ? new Date(report.expected_date) : '')
  const [categorySelected,    setCategorySelected]    = useState(report?.category)
  const [responsibleSelected, setResponsibleSelected] = useState(report?.responsible)
  const [reportType,          setReportType]          = useState(report?.type)
  const [unitSelected,        setUnitSelected]        = useState(report?.unit)
  const [amenitySelected,     setAmenitySelected]     = useState(report?.amenity)
  const [requester,           setRequester]           = useState(report?.requester)
  const [documents,           setDocuments]           = useState([])
  const [showUserForm,        setShowUserForm]        = useState(false)
  const [priority,            setPriority]            = useState(report?.priority)
  const [anomaly,             setAnomaly]             = useState(report?.anomaly)
  const [wasteCategory,       setWasteCategory]       = useState(report?.waste_category)
  const [chargeNumber,        setChargeNumber]        = useState(report?.input_code)
  // const [rootUnits,           setRootUnits]           = useState([])

  const formRef  = useRef(null)

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const createReport = (data, fieldset) => dispatch({
    type:      REPORT_ACTIONS.CREATE,
    data,
    fieldset,
    callbacks: [
      closeModal,
      data => openDetails(data.response, 'reports')
    ]
  })

  const updateReport = (data, fieldset) => dispatch({
    type: REPORT_ACTIONS.UPDATE,
    data,
    fieldset,
    report
  })

  const addFormData = formData => {
    formData.append('category',       categorySelected?.id ? categorySelected?.id.toString() : '')
    formData.append('amenity',        amenitySelected?.id  ? amenitySelected?.id.toString()  : '')
    formData.append('unit',           unitSelected?.id     ? unitSelected?.id.toString()     : '')
    formData.append('control_remark', report?.remark?.id   ? report.remark.id.toString()     : '')
    formData.append('documents', documents.map(d => d.id).toString())
  }

  const callback = report?.id ? updateReport : createReport

  const addDocument    = data => setDocuments(existingDocuments => [...existingDocuments, data])
  const removeDocument = data => setDocuments(existingDocuments => existingDocuments.filter(d => d.id !== data.id))

  const userFilter = useMemo(() => {
    return report?.mandatory_expertise
      ? [{
        id:      'expertise',
        name:    categorySelected?.name,
        filters: { expertise: categorySelected?.id, role: report?.type?.manageable_roles }
      }]
      : [{
        id:      'role',
        name:    'Role',
        filters: { role: report?.type?.manageable_roles?.length ? report?.type?.manageable_roles : '' }
      }]
  }, [categorySelected])

  const changeReportType = type => {
    setReportType(type)
    formRef.current.parentElement.parentElement.scrollTop = 0
  }

  const selectCategory = response => {
    if (report?.mandatory_expertise && response?.id !== categorySelected?.id) setResponsibleSelected(undefined)
    setCategorySelected(response?.object)
  }

  const selectItem = item => {
    console.log('selected', item)
    if (item?.class_plural === 'units') {
      setUnitSelected(item)
      setAmenitySelected(null)
    } else if (item?.class_plural === 'amenities') {
      setAmenitySelected(item)
    }
  }

  const unselectItem = item => {
    console.log('unselected', item)
    if (item.class_plural === 'units') {
      setUnitSelected(null)
      setAmenitySelected(null)
    }
    if (item.class_plural === 'amenities') setAmenitySelected(null)
  }

  // Old selector
  const selectUnit = unit => {
    setUnitSelected(unit)
    if (amenitySelected) return
    setAmenitySelected(undefined)
  }

  useEffect(() => {
    if (report?.id) return
    console.log('unit selected', unitSelected)

    if (amenitySelected) {
      setShowUserForm(!!amenitySelected.suggested_user)
    } else if (unitSelected) {
      // No suggested user for units
      setShowUserForm(!!unitSelected.suggested_user)
    } else {
      setShowUserForm(false)
    }
  }, [unitSelected, amenitySelected])

  const fieldVisible = (field) => {
    switch (current_user.role) {
      case ROLES.EMPLOYEE_MANAGER:
        return field.visible_to_employee_managers
      case ROLES.EMPLOYEE:
        return field.visible_to_employees
      case ROLES.TECHNICIAN_MANAGER:
        return field.visible_to_technician_managers
      case ROLES.TECHNICIAN:
        return field.visible_to_technicians
      default:
        return true
    }
  }

  const dangerIcons = useMemo(() => ({
    pressure:    pressureImg,
    explosions:  explosionImg,
    comburant:   comburantImg,
    fuel:        fuelImg,
    corrovise:   corrosiveImg,
    nocive:      nociveImg,
    toxic:       toxicImg,
    health:      healthImg,
    environment: environementImg,
  }), [])

  useMemo(() => {
    const filters = buildFilterString({
      custom_fields: true,
      amenity_type:  report?.amenity?.type?.id
    })
    fetch(`/report_types/search?${filters}`)
      .then(response => response.json())
      .then(data => setReportTypes(data.response.results))
  }, [])

  return(
    <Form
      formRef        = {formRef}
      callback       = {callback}
      addFormData    = {addFormData}
      submitDisabled = {report?.id && (!report.permissions.can_update_report || !report.permissions.can_update_description)}
      hideSumbit     = {!reportType}
    >
      {!reportType &&
        <Style.ReportTypeContainer>
          {reportTypes.map(type =>
            <Style.ReportTypeBtn
              key     = {type.id}
              color   = {type.color}
              onClick = {() => changeReportType(type)}
            >
              <div style={{ fontSize: '1.5rem' }}>
                <FontAwesomeIcon icon={type.logo} />
              </div>
              <div style={{ textAlign: 'center' }}>{type.name}</div>
            </Style.ReportTypeBtn>
          )}
        </Style.ReportTypeContainer>
      }



      {reportType &&
        <>
          {!report?.id && <Checkbox
            name         = 'self_assign'
            label        = {<><FontAwesomeIcon icon="hand" /> {i18n.t('report.assign_task_to_current_user')}</>}
            defaultValue = {selfAssign}
            // disabled     = {report?.id && !report.permissions.can_update_report}
            marginY      = 'M'
            callback = {val => setSelfAssign(val.isCheck)}
            // required     = {reportType.urgent === TYPE_VALUES.MANDATORY}
          />
          }

          <FormStyle.Header><FontAwesomeIcon icon="passport" />{i18n.t('shared.category')}</FormStyle.Header>
          <Select
            label         = {i18n.t('report_type.report_type')}
            name          = 'report_type'
            search        = {true}
            searchUrl     = '/report_types/search'
            defaultValue  = {[reportType]}
            format        = {{ content: 'name', value: 'id' }}
            filters       = {[{ id: 'report_types', filters: { custom_fields: true } }]}
            disabled      = {report?.id && !report.permissions.can_update_report}
            marginY       = 'M'
            required      = {true}
            callback      = {data => changeReportType(data.object)}
            customOption  = {CustomTypeOption}
          />

          <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}</FormStyle.Header>
          <Input
            type         = 'text'
            name         = 'title'
            label        = {i18n.t('shared.title')}
            defaultValue = {report?.title}
            disabled     = {report?.id && !report.permissions.can_update_report}
            marginY      = 'M'
            required     = {true}
          />
          {reportType.description !== TYPE_VALUES.HIDDEN &&
            <Textarea
              name         = 'description'
              label        = {i18n.t('shared.description')}
              disabled     = {report?.id && !report.permissions.can_update_description}
              defaultValue = {report?.description}
              marginY      = 'M'
              required     = {reportType.description === TYPE_VALUES.MANDATORY}
            />
          }

          {report?.id &&
          [REPORT.STATUS.NEW, REPORT.STATUS.ASSIGNED, REPORT.STATUS.ONGOING, REPORT.STATUS.ON_HOLD].includes(report.status) &&
            <Select
              label         = {i18n.t('report.report_status')}
              name          = 'status'
              defaultValue  = {[{ content: i18n.t(`report.status.${report.status}`), value: report.status }]}
              options       = {[
                { content: i18n.t(`report.status.${REPORT.STATUS.NEW}`),     value: REPORT.STATUS.NEW     },
                { content: i18n.t(`report.status.${REPORT.STATUS.ONGOING}`), value: REPORT.STATUS.ONGOING },
                { content: i18n.t(`report.status.${REPORT.STATUS.ASSIGNED}`), value: REPORT.STATUS.ASSIGNED },
                { content: i18n.t(`report.status.${REPORT.STATUS.ON_HOLD}`), value: REPORT.STATUS.ON_HOLD },
              ]}
              format        = {{ content: 'content', value: 'value' }}
              required
              disabled      = {report?.id && !report.permissions.can_update_report}
              marginY       = 'M'
            />
          }

          {reportType.expertise !== TYPE_VALUES.HIDDEN &&
            <Select
              label         = {i18n.t('expertise.expertise')}
              name          = 'expertise'
              search        = {true}
              searchUrl     = '/expertises/search'
              withEmpty     = {true}
              callback      = {selectCategory}
              emptyCallback = {selectCategory}
              defaultValue  = {[categorySelected]}
              format        = {{ content: 'name', value: 'id' }}
              disabled      = {report?.id && !report.permissions.can_update_report}
              marginY       = 'M'
              required      = {report?.mandatory_expertise || reportType.expertise === TYPE_VALUES.MANDATORY}
            />
          }

          {reportType.anomaly !== TYPE_VALUES.HIDDEN &&
            <Select
              label         = {i18n.t('anomaly.anomaly')}
              name          = 'anomaly'
              search        = {true}
              searchUrl     = '/anomalies/search'
              withEmpty     = {true}
              defaultValue  = {[anomaly]}
              callback      = {selected => setAnomaly(selected.object)}
              format        = {{ content: 'name', value: 'id' }}
              disabled      = {report?.id && !report.permissions.can_update_report}
              marginY       = 'M'
              required      = {reportType.anomaly === TYPE_VALUES.MANDATORY}
              filters       = {[
                {
                  id:      'report_type',
                  name:    i18n.t('anomaly.anomalies'),
                  filters: { report_type_id: reportType.id }
                }
              ]}
            />
          }

          {reportType.hh_name === CONSTANTS.REPORT.TYPE.RECYCLE &&
            <>
              <Select
                label         = {i18n.t('waste_category.waste_category')}
                name          = 'waste_category'
                search
                searchUrl     = '/waste_categories/search'
                withEmpty
                defaultValue  = {[wasteCategory]}
                callback      = {selected => setWasteCategory(selected.object)}
                format        = {{ content: 'description', value: 'id' }}
                disabled      = {report?.id && !report.permissions.can_update_report}
                marginY       = 'M'
              />
              <FormStyle.Header><FontAwesomeIcon icon="exclamation-triangle" />{i18n.t('report.danger')}</FormStyle.Header>
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('pressure')}
                marginY      = 'S'
                name         = 'type_pressure'
                label        = {<div>
                  <img src={dangerIcons.pressure} height='28px' /> {i18n.t('offer_line.danger.pressure')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('explosion')}
                marginY      = 'S'
                name         = 'type_explosive'
                label        = {<div>
                  <img src={dangerIcons.explosions} height='28px' /> {i18n.t('offer_line.danger.explosive')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('comburant')}
                marginY      = 'S'
                name         = 'type_comburant'
                label        = {<div>
                  <img src={dangerIcons.comburant} height='28px' /> {i18n.t('offer_line.danger.comburant')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('combustible')}
                marginY      = 'S'
                name         = 'type_fuel'
                label        = {<div>
                  <img src={dangerIcons.fuel} height='28px' /> {i18n.t('offer_line.danger.fuel')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('corrosif')}
                marginY      = 'S'
                name         = 'type_corrosive'
                label        = {<div>
                  <img src={dangerIcons.corrovise} height='28px' /> {i18n.t('offer_line.danger.corrosive')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('nocif')}
                marginY      = 'S'
                name         = 'type_nocive'
                label        = {<div>
                  <img src={dangerIcons.nocive} height='28px' /> {i18n.t('offer_line.danger.nocive')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('toxique')}
                marginY      = 'S'
                name         = 'type_toxic'
                label        = {<div>
                  <img src={dangerIcons.toxic} height='28px' /> {i18n.t('offer_line.danger.toxic')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('santé')}
                marginY      = 'S'
                name         = 'type_health'
                label        = {<div>
                  <img src={dangerIcons.health} height='28px' /> {i18n.t('offer_line.danger.health')}
                </div>}
              />
              <Checkbox
                defaultValue = {report?.danger_icons?.includes('environnement')}
                marginY      = 'S'
                name         = 'type_environment'
                label        = {<div>
                  <img src={dangerIcons.environment} height='28px' /> {i18n.t('offer_line.danger.environment')}
                </div>}
              />
            </>
          }

          {!!reportType?.custom_fields?.length &&
            reportType.custom_fields.map(field => {
              return fieldVisible(field) && <FieldInput
                key          = {field.id}
                field        = {field}
                defaultValue = {report?.id ? report.custom_fields.find(f => f.id === field.id)?.value : null}
                marginY      = 'M'
                required     = {[TYPE_VALUES.MANDATORY].includes(field.mandatory)}
                disabled     = {report?.id && !report.permissions.can_update_report}
              />
            })
          }

          <FormStyle.Header><FontAwesomeIcon icon="users" />{i18n.t('user.users')}</FormStyle.Header>
          <Select
            label         = {i18n.t('shared.requested_by')}
            name          = 'requester'
            search        = {true}
            searchUrl     = '/users/search'
            defaultValue  = {[requester || current_user]}
            callback      = {selected => setRequester(selected.object)}
            format        = {{ content: 'name', details: 'role', value: 'id' }}
            disabled      = {report?.id && !report.permissions.can_update_report}
            marginY       = 'M'
            detailsLocaleKey = 'shared'
            required
          />

          {reportType.responsible !== TYPE_VALUES.HIDDEN && current_user.managing &&
            <Select
              label            = {i18n.t('shared.responsible')}
              name             = 'responsible'
              search           = {true}
              searchUrl        = '/users/search'
              filters          = {userFilter}
              defaultFilter    = 'expertise'
              defaultValue     = {[responsibleSelected]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              disabled         = {report?.id && !report.permissions.can_update_report}
              marginY          = 'M'
              detailsLocaleKey = 'shared'
              required         = {reportType.responsible === TYPE_VALUES.MANDATORY}
              tooltip          = {i18n.t('report.tooltip.manageable_responsible')}
            />
          }

          {(reportType.amenity !== 'hidden' || reportType.unit !== 'hidden') &&
            <>
              <FormStyle.Header marginY='S'>
                <FontAwesomeIcon icon="location-dot" />
                {[CONSTANTS.REPORT.TYPE.RECYCLE, CONSTANTS.REPORT.TYPE.MOVE].includes(reportType.hh_name)
                  ? i18n.t('report.drop_in')
                  : i18n.t('shared.location_general')
                }
              </FormStyle.Header>

              {!!reportType.amenity_types.length &&
                <Callout type="accent" marginY="S">
                  {i18n.t('report_type.amenity_type_limited', { type: reportType.name, amenity_types: reportType.amenity_types.map(t => t.name).join(', ')})}
                </Callout>
              }
            </>
          }

          {(!report?.id || (report?.id && report.permissions.can_update_report))
            ? (current_company.beta_access && isDesktop)
              ? <SelectEquipmentForm
                units              = {unitSelected    ? [unitSelected]    : []}
                amenities          = {amenitySelected ? [amenitySelected] : []}
                unitFieldOption    = {reportType.unit}
                amenityFieldOption = {reportType.amenity}
                selectCallback     = {selectItem}
                checkCallback      = {selectItem}
                uncheckCallback    = {unselectItem}
                amenityTypeFilter  = {reportType.amenity_types}
                openSelector       = {!report?.id}
                showTreeStatus     = {current_company.permissions.can_use_equipment_usages}
                autoSelectUnit
                showTreeTickets
                uniqueSelect
              />
              : <>
                {(unitSelected?.id || reportType.unit !== TYPE_VALUES.HIDDEN) &&
                  <SelectTree
                    name           = 'unit'
                    label          = {i18n.t('unit.unit')}
                    selected       = {unitSelected}
                    callback       = {selectUnit}
                    withEmpty      = {true}
                    searchUrl      = '/units/rep_search'
                    filters        = {[{
                      id:      'root',
                      name:    'Parent',
                      filters: { roots: 'true', path: 'true' }
                    }]}
                    format         = {{ content: 'name', value: 'id' }}
                    disabled       = {report?.id && !report.permissions.can_update_report}
                    marginY        = 'M'
                    required       = {reportType.unit === TYPE_VALUES.MANDATORY}
                    subFilterName  = 'for_unit'
                    subElementName = {i18n.t('unit.subunit')}
                  />
                }
                {reportType.amenity !== TYPE_VALUES.HIDDEN &&
                        <>
                          {reportType.unit !== TYPE_VALUES.HIDDEN && <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>}
                          <SelectTree
                            name           = 'amenity'
                            label          = {i18n.t('amenity.amenity')}
                            selected       = {amenitySelected}
                            callback       = {setAmenitySelected}
                            withEmpty      = {true}
                            searchUrl      = '/amenities/rep_search'
                            filters        = {unitSelected ?
                              [
                                {
                                  id:      'direct_children',
                                  name:    i18n.t('amenity.exclude_tree'),
                                  filters: {
                                    for_unit:        unitSelected?.id || '',
                                    amenity_type_id: reportType.amenity_types.map(type => type.id).join(','),
                                    path:            true
                                  }
                                },
                                {
                                  id:      'all',
                                  name:    i18n.t('amenity.include_tree'),
                                  filters: {
                                    root_unit_id:    unitSelected?.id || '',
                                    amenity_type_id: reportType.amenity_types.map(type => type.id).join(','),
                                    path:            true
                                  }
                                },
                                {
                                  id:      'ongoing_usage',
                                  name:    i18n.t('equipment_usage.currently_used_amenities'),
                                  filters: {
                                    currently_used:  true,
                                    root_unit_id:    unitSelected?.id || '',
                                    amenity_type_id: reportType.amenity_types.map(type => type.id).join(','),
                                    path:            true
                                  }
                                }
                              ]
                              : [
                                {
                                  id:      'direct_children',
                                  name:    i18n.t('shared.all'),
                                  filters: {
                                    for_unit:        unitSelected?.id || '',
                                    amenity_type_id: reportType.amenity_types.map(type => type.id).join(','),
                                    path:            true
                                  }
                                },
                                {
                                  id:      'ongoing_usage',
                                  name:    i18n.t('equipment_usage.currently_used_amenities'),
                                  filters: {
                                    currently_used:  true,
                                    amenity_type_id: reportType.amenity_types.map(type => type.id).join(','),
                                    path:            true
                                  }
                                }
                              ]}
                            defaultFilter  = "direct_children"
                            format         = {{ content: 'name', value: 'id' }}
                            disabled       = {report?.id && !report.permissions.can_update_report}
                            marginY        = 'M'
                            required       = {reportType.amenity === TYPE_VALUES.MANDATORY}
                            subFilterName  = 'for_amenity'
                            subElementName = {i18n.t('amenity.subamenity')}
                          />
                        </>
                }
              </>
            : <></>
          }

          {reportType.unit !== 'hidden' && current_company.beta_access &&
            <Style.EquipmentDisplayer selected={!!unitSelected} required={reportType.unit === 'mandatory'}>
              <Tag
                background     = {unitSelected ? 'var(--rep-neutral-light)' : reportType.unit === 'mandatory' ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
                color          = {unitSelected ? 'var(--rep-neutral)'       : reportType.unit === 'mandatory' ? 'var(--rep-warning)'       : 'var(--rep-neutral)'}
                icon           = {<FontAwesomeIcon icon={unitSelected ? 'building' : 'times'} />}
                tooltipContent = {unitSelected?.path_string}
                click          = {() => !!unitSelected && current_company.beta_access && setModalInfoWindowProps({ ...unitSelected, resourceType: 'units', fromModal: true })}
              >
                {unitSelected?.name || i18n.t('unit.missing')}
              </Tag>
            </Style.EquipmentDisplayer>
          }

          <div style={{ display: 'flex', justifyContent: 'center', margin: '8px 0'}}>
            {reportType.unit !== 'hidden' && reportType.amenity !== 'hidden' && current_company.beta_access &&
              <FontAwesomeIcon icon="arrow-down" color="var(--rep-neutral-primary)" />
            }
          </div>

          {reportType.amenity !== 'hidden' && current_company.beta_access &&
            <Style.EquipmentDisplayer selected={!!amenitySelected} required={reportType.amenity === 'mandatory'}>
              <Tag
                background     = {amenitySelected ? 'var(--rep-neutral-light)' : reportType.amenity === 'mandatory' ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
                color          = {amenitySelected ? 'var(--rep-neutral)'       : reportType.amenity === 'mandatory' ? 'var(--rep-warning)'       : 'var(--rep-neutral)'}
                icon           = {<FontAwesomeIcon icon={amenitySelected ? 'gear' : 'times'} />}
                tooltipContent = {amenitySelected?.path_string}
                click          = {() => !!amenitySelected && current_company.beta_access && setModalInfoWindowProps({...amenitySelected, resourceType: 'amenities', fromModal: true})}
              >
                {amenitySelected?.name || i18n.t('amenity.missing')}
              </Tag>
            </Style.EquipmentDisplayer>
          }

          {[CONSTANTS.REPORT.TYPE.RECYCLE, CONSTANTS.REPORT.TYPE.MOVE].includes(reportType.hh_name) &&
            <>
              <FormStyle.Header><FontAwesomeIcon icon="truck-ramp-box" />{i18n.t('report.drop_off')}</FormStyle.Header>
              <Input
                type         = 'text'
                name         = 'drop_off'
                defaultValue = {report?.drop_off}
                required     = {reportType.location === TYPE_VALUES.MANDATORY}
                disabled     = {report?.id && !report.permissions.can_update_report}
                marginY      = 'M'
              />
            </>
          }

          {reportType.location !== TYPE_VALUES.HIDDEN &&
            <Input
              label        = {i18n.t('report.location_informations')}
              type         = "text"
              name         = "location"
              defaultValue = {report?.location}
              required     = {reportType.location === TYPE_VALUES.MANDATORY}
              marginY      = 'M'
              disabled     = {report?.id && !report.permissions.can_update_report}
            />
          }

          <FormStyle.Header marginY = 'M'><FontAwesomeIcon icon="calendar-days" />{i18n.t('shared.planning')}</FormStyle.Header>
          {report?.id && report?.deadline
            ? <div>
              <Tag
                background     = 'var(--rep-neutral-light)'
                color          = 'var(--rep-neutral-primary)'
                marginY        = 'M'
                icon           = {<FontAwesomeIcon icon="hand-sparkles" />}
                tooltipContent = {i18n.t('report.deadline')}
              >
                {i18n.t('report.deadline')}: {new Date(report.deadline).toLocaleDateString(i18n.locale, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })}
              </Tag>
            </div>
            : <DatePicker
              name  = "deadline"
              label = {i18n.t('report.deadline')}
            />
          }

          {report?.id  && report.permissions.can_set_expected_date &&
            <DatePicker
              label        = {i18n.t('report.expected_date')}
              name         = 'expected_date'
              date         = {expectedDate}
              disabled     = {report?.id && !report.permissions.can_update_report}
              required     = {report?.id && report.permissions.must_set_expected_date}
              allowClear
            />
          }

          <Select
            name          = 'report_priority'
            label         = {i18n.t('report.priority')}
            search        = {true}
            searchUrl     = '/report_priorities/search'
            defaultValue  = {[priority]}
            callback      = {selected => setPriority(selected.object)}
            format        = {{ content: 'name', value: 'id', details: 'duration_string' }}
            disabled      = {report?.id && !report.permissions.can_update_report}
            withEmpty     = {true}
            marginY       = 'M'
          />

          {reportType.urgent !== TYPE_VALUES.HIDDEN &&
            <Checkbox
              name         = 'urgent'
              label        = {<><FontAwesomeIcon icon="exclamation-circle" /> {i18n.t('report.urgent_question')}</>}
              defaultValue = {report?.urgent}
              disabled     = {report?.id && !report.permissions.can_update_report}
              marginY      = 'M'
              required     = {reportType.urgent === TYPE_VALUES.MANDATORY}
            />
          }

          {/* # reportType.assignee !== TYPE_VALUES.HIDDEN && reportType.manageable_roles.includes(current_user.role) && */}
          {!report?.id && !selfAssign &&

            <Button
              color   = "var(--rep-success)"
              icon    = {<FontAwesomeIcon icon={showUserForm ? 'angle-down' : 'plus'} />}
              click   = {() => setShowUserForm(showUserForm => !showUserForm)}
              marginY = 'M'
            >
              Ajouter un intervenant
            </Button>
          }
          {showUserForm && reportType.manageable_roles.includes(current_user.role) &&
            <SelectDoerForm suggested={amenitySelected?.suggested_user} />
          }

          {reportType.charge_number !== TYPE_VALUES.HIDDEN &&
            <>
              <FormStyle.Header marginY='M'><FontAwesomeIcon icon="receipt" />{i18n.t('invoice_contact.invoice_contact')}</FormStyle.Header>
              <Select
                name          = 'charge_number'
                label         = {i18n.t('charge_number.charge_number')}
                search        = {true}
                searchUrl     = '/charge_numbers/search'
                withEmpty     = {true}
                defaultValue  = {[chargeNumber]}
                callback      = {selected => setChargeNumber(selected.object)}
                format        = {{ content: 'number', value: 'id', details: 'type' }}
                disabled      = {report?.id && !report.permissions.can_update_report}
                marginY       = 'M'
                required      = {reportType.charge_number === TYPE_VALUES.MANDATORY}
              />
            </>
          }

          {!report?.id && reportType.documents !== TYPE_VALUES.HIDDEN &&
            <FileUploader
              objectType      = 'report'
              // objectId        = {}
              closeModalAfter = {false}
              updateMethods   = {{ addDocument, removeDocument }}
              files           = {documents}
              gridSize        = {4}
              required        = {reportType.documents !== TYPE_VALUES.MANDATORY}
              multiple
              destroyable
              thumbnailDestroy
              grid
            />
          }
        </>
      }
    </Form>
  )
}

export default ReportForm
