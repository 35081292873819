import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button      from '@components/Button'
import Checkbox    from '@components/Form/Checkbox'
import Tag         from '@components/Tag'
import WithTooltip from '@components/WithTooltip'

import { useGlobalContextState } from '@context/GlobalContext'

import * as Style from './style'

import { TreeItemProps } from './types.d'

const TreeItem: React.FC<TreeItemProps> = ({
  /* The item with at least : { id:, name:, path: } */
  item,
  /* If the item should be open or not */
  isFolded = false,
  /* Identation level */
  indent = 0,
  /* Elements higher in the tree */
  before,
  /* Is the item highlighted or not */
  highlighted,
  /* Array of highlighted items */
  highlightedItems = [],
  /* Key to find id for highlighted items */
  highlightedIdKey,
  /* If amenities should be filetered by Amenity type */
  amenityTypeFilter = [],
  unitFieldOption,
  amenityFieldOption,
  /* If element is clickable or not */
  readOnly,
  /* Will open ModalSideWindow */
  fromModal = false,
  /* If the element is selectable (will trigger selectCallback) */
  selectable = false,
  /* Callback when selecting an element */
  selectCallback,
  /* Will display machine stop status */
  showStatus = false,
  /* Will display ticket number */
  showTickets = false,
  /* Component to replace the checkbox */
  checkboxComponent
}) => {

  const { i18n, current_company, setInfoWindowProps, setModalInfoWindowProps, isDesktop } = useGlobalContextState()

  const [folded,         setFolded]         = useState(isFolded)
  const [children,       setChildren]       = useState({ items: [], count: null })
  const [amenityLoading, setAmenityLoading] = useState(false)
  const [unitLoading,    setUnitLoading]    = useState(false)
  const [page,           setPage]           = useState(1)
  const [selected,       setSelected]       = useState(false)
  const [hasNextPage,    setHasNextPage]    = useState(true)

  useEffect(() => {
    if (highlightedItems.find(i => item[highlightedIdKey] === i[highlightedIdKey] && item.class_plural === i.class_plural)) {
      fetchItems(1)
    }
  }, [])

  useEffect(() => {
    if (highlightedItems.find(i => item[highlightedIdKey] === i[highlightedIdKey] && item.class_plural === i.class_plural)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [highlightedItems])

  const selectItem = () => {
    if (!selectCallback) return
    setSelected(!selected)
    selectCallback(item, !selected)
  }

  const toggleItem = () => {
    if (folded) {
      setChildren({ items: [], count: null })
      setFolded(false)
      if (item.has_children || item.has_amenities) { fetchItems(1) }
    } else {
      setFolded(true)
    }
  }

  const insertChildren = data => {
    if (data.page > 1) {
      setChildren(current => ({ ...current,  items: [...current.items, ...data.items] }))
    }
    else {
      setChildren(data)
    }

    setPage(data.page)
    setHasNextPage(!!data.next_page)
  }

  const fetchItems = selectedPage => {
    switch(item?.class_plural) {
      case 'amenities': return fetchAmenities(selectedPage)
      case 'units':     return fetchUnits(selectedPage)
    }
  }

  const fetchAmenities = selectedPage => {
    if (amenityFieldOption === 'hidden') return
    setAmenityLoading(true)
    fetch(`/amenities/${item.id}/rep_tree_search?page=${selectedPage}&reports=true&path=true&path_string=true&amenity_type_id=${amenityTypeFilter.map(t => t.id).join(',')}`)
      .then(data => data.json())
      .then(data => {
        insertChildren(data)
        setAmenityLoading(false)
      })
  }

  const fetchUnits = selectedPage => {
    setUnitLoading(true)
    fetch(`/units/${item.id}/rep_tree_search?page=${selectedPage}&amenities=${amenityFieldOption !== 'hidden'}&path=true&reports=true&path_string=true&amenity_type_id=${amenityTypeFilter.map(t => t.id).join(',')}`)
      .then(data => data.json())
      .then(data => {
        insertChildren(data)
        setUnitLoading(false)
      })
  }

  const itemIcon = useMemo(() => {
    switch(item?.class_plural) {
      case 'amenities': return <FontAwesomeIcon icon="gear" style={{ marginRight: '4px' }} />
      case 'units':     return <FontAwesomeIcon icon="building" style={{ marginRight: '4px' }} />
    }
  }, [item])

  const openDetails = (object, type) => {
    current_company.beta_access
      ? fromModal
        ? setModalInfoWindowProps({...object, resourceType: type, fromModal})
        : setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const itemLink = useMemo(() => {
    switch(item?.class_plural) {
      case 'amenities': return <Tag
        click          = {() => openDetails(item, 'amenities')}
        color          = 'var(--rep-primary)'
        hover          = 'var(--rep-primary-light)'
        tooltipContent = {i18n.t('actions.see')}
        icon           = {<FontAwesomeIcon icon="eye" />}
      />
      case 'units':     return <Tag
        click          = {() => openDetails(item, 'units')}
        icon           = {<FontAwesomeIcon icon="eye" />}
        color          = 'var(--rep-primary)'
        hover          = 'var(--rep-primary-light)'
        tooltipContent = {i18n.t('actions.see')}
      />
    }
  }, [item])

  return(
    <>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Style.TreeItem
          indent    = {indent}
          highlight = {highlighted}
          action    = {!!selectItem}
          style     = {{marginLeft: `${(indent ? (indent - 1) : 0) * 12}px`}}
        >
          {(item?.has_children || item?.has_amenities)
            ? <Button
              click = {toggleItem}
              color = 'var(--rep-neutral-primary)'
            >
              {folded
                ? <FontAwesomeIcon icon="caret-right" />
                : <FontAwesomeIcon icon="caret-down" />
              }
            </Button>
            : isDesktop && <FontAwesomeIcon icon="square" color="transparent" width="2em" />
          }
          {(selectable && !readOnly)
            ? <div onClick={selectItem}>
              {checkboxComponent
                ? checkboxComponent
                : <Checkbox
                  name         = "selected"
                  defaultValue = {selected}
                  shouldReset  = {selected}
                />
              }
            </div>
            : isDesktop && <div></div>
          }

          <Style.TreeItemContent onClick = {() => selectCallback ? selectCallback(item, !selected) : toggleItem()}>
            <Style.TreeItemName>
              <WithTooltip content = {item?.path_string}>
                {itemIcon}
                {showStatus &&
                <Tag
                  icon           = {<FontAwesomeIcon icon="circle" color={item?.stopped ? 'var(--rep-danger)' : 'var(--rep-success)'} />}
                  tooltipContent = {`Status : ${item?.stopped ? i18n.t('machine_stop.status.stopped') : i18n.t('machine_stop.status.working')}`}
                />
                }

                {item?.name}
              </WithTooltip>
            </Style.TreeItemName>
            <div style={{display: 'flex'}}>
              {showTickets && !!item?.reports_count &&
                <Tag
                  icon           = {<FontAwesomeIcon icon="receipt" />}
                  color          = 'var(--rep-accent)'
                  background     = 'var(--rep-accent-light)'
                  tooltipContent = {`${item?.reports_count} ${i18n.t('report.ongoing_reports')}`}
                >
                  {item?.reports_count}
                </Tag>
              }
            </div>
          </Style.TreeItemContent>

        </Style.TreeItem>
        {!readOnly && itemLink}
      </div>

      {!folded && children.items.map(it =>
        <TreeItem
          key                = {`${it.class_plural}-${it.id}`}
          item               = {it}
          indent             = {indent + 1}
          highlighted        = {!!highlightedItems.find(i => it.id === i[highlightedIdKey] && it.class_plural === i.class_plural)}
          highlightedItems   = {highlightedItems}
          highlightedIdKey   = {highlightedIdKey}
          before             = {before}
          fromModal          = {fromModal}
          selectable         = {selectable}
          selectCallback     = {selectCallback}
          showStatus         = {showStatus}
          showTickets        = {showTickets}
          amenityTypeFilter  = {amenityTypeFilter}
          unitFieldOption    = {unitFieldOption}
          amenityFieldOption = {amenityFieldOption}
          isFolded           = {!highlightedItems.find(i => it.id === i[highlightedIdKey] && it.class_plural === i.class_plural)}
          checkboxComponent  = {checkboxComponent}
        />
      )}

      {unitLoading &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px', color: 'var(--rep-neutral-primary)', margin: '4px 0'}}>
          <FontAwesomeIcon icon="spinner" spin /> Loading equipments…
        </div>
      }
      {amenityLoading &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px', color: 'var(--rep-neutral-primary)', margin: '4px 0'}}>
          <FontAwesomeIcon icon="spinner" spin /> Loading equipments…
        </div>
      }

      {!folded && (!amenityLoading && !unitLoading) && hasNextPage &&
        <Style.TreeItem
          indent  = {indent + 3}
          action  = {!!selectItem}
          style   = {{marginLeft: `${(indent ? indent + 3 : 3) * 12}px`, cursor: 'pointer '}}
        >
          <Button
            color = 'var(--rep-neutral-primary)'
            click = {() => fetchItems(page + 1)}
          >
            {i18n.t('shared.load_more')}
          </Button>
        </Style.TreeItem>
      }
    </>
  )
}

export default TreeItem
