import styled from 'styled-components'

export const MaterialContainer = styled.div`
  margin-top: 26px;
  .react-qr-reader > div {
    padding-top: 50%;
  }
`

export const MaterialLine = styled.div`
  display:          block;
  align-items:      center;
  justify-content:  space-between;
  margin:           16px 0;
  padding:          8px;
  border-bottom:    1px solid var(--rep-primary-light);
  border:           1px solid var(--rep-primary-middle);
  border-radius:    5px;
`

export const MaterialDescription = styled.div`
  flex-grow: 1;
`

export const MaterialNomination = styled.div`
  display:      flex;
  align-items:  center;
  gap:          8px;
  margin-top:   4px;
`

export const MaterialBrand = styled.div`
  color:          var(--rep-neutral-primary);
  text-transform: uppercase;
  display:        flex;
  align-items:    center;
  gap:            8px;
  font-size:      .8em;
`

export const MaterialQuantityInput = styled.div`
  display:         flex;
  align-items:     center;
  justify-content: ${props => props.isDesktop ? 'initial' : 'space-between'};
  margin-top:      ${props => props.isDesktop ? 'initial' : '16px'};
  gap:          8px;
  /* input { width: 80px } */
  div   {
    margin-top: 0;
    flex-grow:  unset;
  }
`

export const MaterialQuantityPrice = styled.div`
  flex-shrink: 0;
`

export const MaterialName = styled.div`
  font-size: 1rem;
  /* font-weight: 500; */
`

export const MaterialDescriptionDetails = styled.div`
  width:         100%;
  max-width:     40vw;
  white-space:   nowrap;
  overflow:      hidden;
  text-overflow: ellipsis;
  font-size:     .8rem;
`

export const MaterialStock = styled.span`
  color: var(--rep-primary);
  width: max-content;
`

export const Quantity = styled.span`
  display:         flex;
  justify-content: center;
  align-items:     center;
  height:          25px;
  min-width:       25px;
  font-weight:     600;
  color:          white;
  background:      var(--rep-danger);
  border-radius:   15px;
`
