import React, { useState }      from 'react'

import WithTooltip from '@components/WithTooltip'

import * as Style from './style'

import TagProps, { TagContentProps }   from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const Tag: React.FC<TagProps> = ({
  icon,
  iconAnimateClick = false,
  children,
  color,
  border,
  background,
  hover,
  style,
  click,
  marginY,
  tooltipContent,
  tooltipTitle,
  draggable = false
}) => {
  return (
    <>
      {!!tooltipContent &&
        <WithTooltip
          title    = {tooltipTitle}
          content  = {tooltipContent}
        >
          <TagContent
            click            = {click}
            color            = {color}
            border           = {border}
            background       = {background}
            hover            = {hover}
            style            = {style}
            icon             = {icon}
            iconAnimateClick = {iconAnimateClick}
            marginY          = {marginY}
            draggable        = {draggable}
          >
            {children}
          </TagContent>
        </WithTooltip>
      }
      {!tooltipContent &&
        <TagContent
          click      = {click}
          color      = {color}
          border     = {border}
          background = {background}
          hover      = {hover}
          style      = {style}
          icon       = {icon}
          marginY    = {marginY}
        >
          {children}
        </TagContent>
      }
    </>
  )
}

const TagContent: React.FC<TagContentProps>  = ({
  children,
  click,
  color,
  border,
  background,
  hover,
  style,
  icon,
  iconAnimateClick,
  draggable,
  marginY,
}) => {

  const { isDesktop } = useGlobalContextState()

  const [animate, setAnimate] = useState(false)

  const clickTag = () => {
    if (iconAnimateClick) {
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
      }, 1000)
    }
    click ? click() : undefined
  }
  return <Style.Tag
    marginY         = {marginY}
    clickable       = {!!click}
    color           = {color}
    border          = {border}
    background      = {background}
    hover           = {hover}
    onClick         = {clickTag}
    style           = {style}
    draggable       = {draggable}
    isDesktop       = {isDesktop}
  >
    {!!icon &&
      <Style.TagIcon hasContent={!!children} animate={animate}>
        {icon}
      </Style.TagIcon>
    }
    <div>{children}</div>
  </Style.Tag>
}

export default Tag
