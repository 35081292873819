import styled from 'styled-components'

export const TreeItem = styled.div`
  display: flex;
  align-items: center;
  color: var(--rep-neutral-primary);
  gap: 4px;
  flex-grow: 1;
  padding: 4px;
  border-radius: 4px;
  background: ${props => props.highlight ? 'var(--rep-neutral-light)' : 'white'};
  user-select: none;
  &:hover {
    cursor: ${props => props.action ? 'pointer' : 'default'};
    /* cursor: pointer; */
    background: var(--rep-neutral-light)
  }
`

export const TreeItemIcon = styled.div`
  display: inline-block;
  width: 12px;
  margin-right: 4px;
`

export const TreeItemName = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const TreeItemTitle = styled.div`
  /* flex: .8; */
`

export const TreeItemContent = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
`
