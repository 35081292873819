import React, { useMemo } from 'react'

import Button           from '@components/Button'
import MeasureDetails   from '../Details'
import MeasureValueForm from './Form'
import WithTooltip      from '@components/WithTooltip'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from '../style'

import IMeasure         from '@interfaces/IMeasure.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureNumberProps {
  measure:       IMeasure
  updateMethods: {
    measurment: (data: IRequestResponse) => void
  }
}

const MeasureNumber: React.FC<MeasureNumberProps> = ({ measure, updateMethods }) => {

  const { i18n, showModal } = useGlobalContextState()

  const color = useMemo(() => {
    if (measure.last_value?.state === 'danger') {
      return '--rep-danger'
    } else if (measure.last_value?.state === 'warning') {
      return '--rep-warning'
    } else {
      return '--rep-success'
    }
  }, [measure])

  return(
    <Style.NumberBlock color={color}>
      <Style.Header>
        <Style.Name>
          {measure.name}
        </Style.Name>
        <Button
          color      = {color}
          background = 'white'
          hover      = {`var(${color}-light)`}
          icon       = {<FontAwesomeIcon icon="plus" />}
          click      = {() => showModal({
            title:   i18n.t('measurment.actions.add_value'),
            content: <MeasureValueForm
              measure       = {measure}
              updateMethods = {updateMethods}
            />
          })}
        />
      </Style.Header>
      {
        measure.last_value
          ? <>
            <Style.Content
              onClick  = {() => showModal({
                title:   measure.name,
                content: <MeasureDetails measure = {measure}/>
              })}
            >
              <WithTooltip
                title   = {i18n.t('measurment.comment')}
                content = {measure.last_value.comment ? measure.last_value.comment : i18n.t('measurment.no_comment')}
              >
                <Style.Number>{measure.last_value.content}</Style.Number>
              </WithTooltip>
              <Style.Difference color={color}>
                {measure.last_value.difference === 0
                  ? <FontAwesomeIcon icon="equals" fade={['danger', 'warning'].includes(measure.last_value.state)} />
                  : measure.last_value.difference > 0
                    ? <><FontAwesomeIcon icon="arrow-up" fade={['danger', 'warning'].includes(measure.last_value.state)} /> {measure.last_value.difference}</>
                    : <><FontAwesomeIcon icon="arrow-down" fade={['danger', 'warning'].includes(measure.last_value.state)} /> {measure.last_value.difference}</>
                }

              </Style.Difference>
            </Style.Content>
            <Style.Date>
              {new Intl.DateTimeFormat(i18n.locale).format(new Date(measure.last_value.date))}
            </Style.Date>
          </>
          : <>
            <Style.Content>
              <div>{i18n.t('measurment.no_value')}</div>
            </Style.Content>
            <div></div>
          </>
      }

    </Style.NumberBlock>
  )
}

export default MeasureNumber
