import React from 'react'
import { buildFilterString } from '@utils/filterString'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FETCH                 = 'UNIT_FETCH'
const CREATE                = 'UNIT_CREATE'
const UPDATE                = 'UNIT_UPDATE'
const ARCHIVE               = 'UNIT_ARCHIVE'
const UNARCHIVE             = 'UNIT_UNARCHIVE'
const ADD_TO_PLAN           = 'UNIT_ADD_TO_PLAN'
const ADD_USERS             = 'UNIT_ADD_USERS'
const ADD_FAVOURITE         = 'UNIT_ADD_FAVOURITE'
const EDIT_FAVOURITE        = 'UNIT_EDIT_FAVOURITE'
const REMOVE_FAVOURITE      = 'UNIT_REMOVE_FAVOURITE'
const FETCH_ONGOING_REPORTS = 'UNIT_FETCH_ONGOING_REPORTS'

export const UNIT_ACTIONS = {
  FETCH:                 FETCH,
  CREATE:                CREATE,
  UPDATE:                UPDATE,
  ARCHIVE:               ARCHIVE,
  UNARCHIVE:             UNARCHIVE,
  ADD_USERS:             ADD_USERS,
  ADD_FAVOURITE:         ADD_FAVOURITE,
  EDIT_FAVOURITE:        EDIT_FAVOURITE,
  REMOVE_FAVOURITE:      REMOVE_FAVOURITE,
  ADD_TO_PLAN:           ADD_TO_PLAN,
  FETCH_ONGOING_REPORTS: FETCH_ONGOING_REPORTS
}

export function unitReducer(state, action) {

  const { updateMethods, fetchApi } = state

  const callbacks = action.callbacks || []
  const fieldset  = action.fieldset

  const params    = buildFilterString(action.params)

  switch (action.type) {
    case FETCH:
      fetchApi({
        url:       `/units/${action.unit.id}/rep_show.json?${params}`,
        callback:  updateMethods.unit,
        followUps: callbacks
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/units/${action.unit.id}/rep_update.json?${params}`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        // callback:  updateMethods.unit,
        followUps: callbacks
      })
      return state

    case ARCHIVE:
      fetchApi({
        url:         `/units/${action.unit.id}/rep_archive.json?${params}`,
        method:      'PATCH',
        body:        action.data,
        // callback:    updateMethods.unit,
        followUps:   callbacks,
        withLoading: true

      })
      return state

    case UNARCHIVE:
      fetchApi({
        url:       `/units/${action.unit.id}/rep_unarchive.json?${params}`,
        method:    'PATCH',
        body:      action.data,
        // callback:  updateMethods.unit,
        followUps: callbacks
      })
      return state

    case ADD_USERS:
      fetchApi({
        url:       `/units/${action.unit.id}/rep_update_users.json?${params}`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        // callback:  data => updateMethods.unit(data.response),
        followUps: callbacks
      })
      return state

    case ADD_FAVOURITE:
      fetchApi({
        url:       `/favourite_providers/rep_create.json?${params}`,
        method:    'POST',
        body:      action.data,
        fieldset,
        // callback:  data => updateMethods.unit(data.response),
        followUps: callbacks
      })
      return state

    case EDIT_FAVOURITE:
      fetchApi({
        url:       `/favourite_providers/${action.data.favourite_provider_id}/rep_update.json?${params}`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        // callback:  data => updateMethods.unit(data.response),
        followUps: callbacks
      })
      return state

    case REMOVE_FAVOURITE:
      fetchApi({
        url:       `/favourite_providers/${action.data.id}/rep_destroy.json`,
        method:    'DELETE',
        body:      action.data,
        // callback:  data => updateMethods.unit(data.response),
        followUps: callbacks
      })
      return state

    case ADD_TO_PLAN:
      fetchApi({
        url:       `/units/${action.unit.id}/rep_add_to_plan`,
        method:    'POST',
        fieldset,
        body:      action.data,
        followUps: callbacks
      })
      return state

    case FETCH_ONGOING_REPORTS:
      const page = action.page || action.pagination?.next || 1

      fetchApi({
        url:       `/reports/rep_ongoing?page=${page}&unit_id=${action.unit.id}`,
        followUps: callbacks
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
