import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar         from '@components/Avatar'
import AvatarList     from '@components/AvatarList'
import Button         from '@components/Button'
import Callout        from '@components/Callout'
import Chat           from '@components/Chat'
import Dropdown       from '@components/Dropdown'
import EndReport      from '@components/Report/EndReport'
import Expander       from '@components/Expander'
import FieldTag       from '@components/Form/FieldTag'
import MachineStopTag from '@components/MachineStop/MachineStopTag'
import ReportForm     from '@components/Report/Form'
import Switch         from '@components/Switch'
import Tag            from '@components/Tag'
import WithTooltip    from '@components/WithTooltip'


import ManagerActions    from '@pages/Reports/actions/managerActions'
import ReportCosts       from '@pages/Reports/Show/ReportCosts'
import ReportDocuments   from '@pages/Reports/Show/ReportDocuments'
import ReportMaintenance from '@pages/Reports/Show/ReportRemarks'
import ReportTask        from '@pages/Reports/Show/ReportTasks'
import RequestForm       from '@components/Material/Request/RequestForm'
import WorkorderPrint    from '@components/Report/WorkorderPrint'

import * as HeaderStyles from '@pages/Reports/Show/style'
import * as InfoStyle from '@components/InfoWindow/style'

import comburantImg    from '@pages/Reports/Show/images/comburant.png'
import corrosiveImg    from '@pages/Reports/Show/images/corrosive.png'
import environementImg from '@pages/Reports/Show/images/environement.png'
import explosionImg    from '@pages/Reports/Show/images/explosion.png'
import fuelImg         from '@pages/Reports/Show/images/fuel.png'
import healthImg       from '@pages/Reports/Show/images/health.png'
import nociveImg       from '@pages/Reports/Show/images/nocive.png'
import pressureImg     from '@pages/Reports/Show/images/pressure.png'
import toxicImg        from '@pages/Reports/Show/images/toxic.png'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { buildFilterString } from '@utils/filterString'

// import IReport from '@interfaces/IReport.d'

import { REPORT_ACTIONS } from '@reducers/reportReducer'

// interface InfoReportProps {
//   item: IReport
// }

const InfoReport = ({
  readOnly,
  fromModal,
  sidebarWidth,
  infoHistory,
  historyBack,
  closeInfoWindow
}) => {

  const { initAPI, i18n, isDesktop, fetchApi, current_user, CONSTANTS, setInfoWindowProps, showModal, current_company } = useGlobalContextState()
  const { report, reportDispatch, updateMethods } = useReportContextState()

  const [view,     setView]     = useState(CONSTANTS.VIEW.VIEW_TASKS)
  const [expanded, setExpanded] = useState(false)
  const [loading,  setLoading]  = useState(true)

  const { REPORT, REPORT_TYPES, DANGER_ICONS, REMARK } = CONSTANTS
  const { TYPE_VALUES } = REPORT_TYPES

  const containerRef = useRef(null)
  const changeView = selected => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setView(selected)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    urlParams.set('info_type', 'reports')
    urlParams.set('info_id',    report.id)

    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
  }, [report])

  const API = initAPI({ reportId: report.id })

  const filters = {
    tasks:         true,
    costs:         true,
    documents:     true,
    users:         true,
    conversations: true,
    charge_number: true,
    measurments:   true,
    machine_stop:  true,
    remarks:       true,
    custom_fields: true,
    permissions:   true,
  }
  const filterString = useMemo(() => buildFilterString(filters), [])

  const fetchReport = () => reportDispatch({
    type:      REPORT_ACTIONS.FETCH,
    filters:   filterString,
    report:    report,
    callbacks: [() => setLoading(false)]
  })

  useEffect(() => {
    setLoading(true)
    fetchReport()
  }, [report.id])

  const uncancelReport = () => reportDispatch({
    type:      REPORT_ACTIONS.UNCANCEL,
    report:    report,
    callbacks: [
      () => API.fetchTasks().then(updateMethods.tasks),
      () => API.fetchCosts().then(updateMethods.costs)
    ]
  })
  const generateFullReport  = () => reportDispatch({ type: REPORT_ACTIONS.GENERATE_FULL_REPORT, report })
  const generateCostsReport = () => reportDispatch({ type: REPORT_ACTIONS.GENERATE_COSTS_REPORT, report })
  const displayWorkOrder = () => {
    if (current_user.managing) {
      return showModal({
        title:   i18n.t('report.work_order'),
        content: <WorkorderPrint report={report} />
      })
    }
    displayWasteReport
  }

  const displayWasteReport = () => {
    return fetchApi({
      url:      `/reports/${report.id}/generate_work_order?user_id=${current_user.id}`,
      method:   'POST',
      callback: data => { window.open(`${report.id}/download_pdf?document_id=${data.response.attributes.table.id}`), '_blank', 'noopener,noreferrer' }
    })
  }
  const actions = useMemo(() => {
    const resultActions = []

    if (!report?.permissions) return resultActions

    if (report.permissions.can_update_report) resultActions.push({
      icon:    <FontAwesomeIcon icon="pen" />,
      color:   'var(--rep-primary)',
      content: current_user.role === CONSTANTS.USER.ROLES.EMPLOYEE_MANAGER && current_company.permissions.employee_manager_ticket_prefiltering
        ? i18n.t('actions.assign')
        : i18n.t('actions.edit'),
      click: () => showModal({
        title:   i18n.t('report.edit_report'),
        content: <ReportForm
          report        = {report}
          updateMethods = {updateMethods}
        />,
      })
    })

    if (report.permissions.can_create_material_request) resultActions.push({
      icon:    <FontAwesomeIcon icon="code-pull-request" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('material_request.material_request'),
      click:   () => showModal({
        title:   i18n.t('material_request.actions.prepare_request'),
        content: <RequestForm report={report} callback={() => API.fetchTasks().then(updateMethods.tasks)} />,
      })
    })

    if (report.permissions.can_access_intervention_report) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.intervention_report'),
      click:   generateFullReport
    })

    if (report.permissions.can_access_intervention_report) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.costs_report'),
      click:   generateCostsReport
    })

    if (report.permissions.can_access_work_order) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.work_order'),
      click:   displayWorkOrder
    })

    if (report.type.hh_name === REPORT.TYPE.RECYCLE) resultActions.push({
      icon:    <FontAwesomeIcon icon="biohazard" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.report_types.recycle_list'),
      click:   displayWasteReport
    })

    if (report.permissions.can_update_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="edit" />,
      color:      'var(--rep-primary)',
      background: 'var(--rep-primary-light)',
      content:    report.permissions.can_prefilter_report && !report.permissions.can_manage_report && !report.responsible
        ? i18n.t('actions.assign')
        : i18n.t('actions.edit'),
      borderTop: true,
      click:     () => showModal({
        title:   i18n.t('report.edit_report'),
        content: <ReportForm
          report        = {report}
          updateMethods = {updateMethods}
        />,
      })
    })

    if (report.permissions.can_cancel_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="trash-alt" />,
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      content:    i18n.t('report.actions.cancel_report'),
      borderTop:  true,
      click:      () => showModal({
        title:   i18n.t('report.actions.cancel_report'),
        content: <EndReport
          step          = 'cancel'
          report        = {report}
          dispatch      = {reportDispatch}
          updateMethods = {updateMethods}
        />,
      })
    })
    if (report.permissions.can_uncancel_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="clock-rotate-left" />,
      color:      'var(--rep-warning)',
      background: 'var(--rep-warning-light)',
      content:    i18n.t('actions.reactivate'),
      borderTop:  true,
      click:      uncancelReport
    })
    return resultActions
  }, [report])

  const managerActions = useMemo(() => {
    if (!report || !report.permissions) return <div></div>
    return <ManagerActions options={{
      edit: false
    }}/>
  }, [report, report?.permissions])

  const options = useMemo<object>(() => {
    return { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'  }
  }, [])

  const switchOptions = useMemo(() => {
    if (!report || !report.permissions) return <div></div>
    const options = [
      { content: i18n.t('todo.todos'),           icon: <FontAwesomeIcon icon="list" />,    value: CONSTANTS.VIEW.VIEW_TASKS },
      { content: i18n.t('shared.conversations'), icon: <FontAwesomeIcon icon="comment" />, value: CONSTANTS.VIEW.VIEW_CHAT },
    ]
    if (report.type.document !== TYPE_VALUES.HIDDEN) {
      options.push({ content: i18n.t('shared.documents'),  icon: <FontAwesomeIcon icon="receipt" />, value: CONSTANTS.VIEW.VIEW_INFO })
    }
    if (report.legal)
      options.push({
        content: i18n.t('control_remarks.name'),
        icon:    <FontAwesomeIcon icon="scale-balanced" />,
        value:   CONSTANTS.VIEW.VIEW_REMARKS
      })
    if (report.permissions.can_see_costs)
      options.push({
        content: i18n.t(`offer_line.${current_user.managing ? 'costs' : 'activity'}`),
        icon:    <FontAwesomeIcon icon="history" /> ,
        value:   CONSTANTS.VIEW.VIEW_COSTS
      })
    return options
  }, [report])

  const dangerIcons = useMemo(() => {
    if (!report || !report.status) { return [] }
    const icons = []
    if (report.danger_icons.includes(DANGER_ICONS.PRESSURE))    icons.push({ icon: pressureImg,     content: i18n.t('offer_line.danger.pressure') })
    if (report.danger_icons.includes(DANGER_ICONS.EXPLOSION))   icons.push({ icon: explosionImg,    content: i18n.t('offer_line.danger.explosive') })
    if (report.danger_icons.includes(DANGER_ICONS.OXIDIZER))    icons.push({ icon: comburantImg,    content: i18n.t('offer_line.danger.comburant') })
    if (report.danger_icons.includes(DANGER_ICONS.FUEL))        icons.push({ icon: fuelImg,         content: i18n.t('offer_line.danger.fuel') })
    if (report.danger_icons.includes(DANGER_ICONS.CORROSIVE))   icons.push({ icon: corrosiveImg,    content: i18n.t('offer_line.danger.corrosive') })
    if (report.danger_icons.includes(DANGER_ICONS.NOCIVE))      icons.push({ icon: nociveImg,       content: i18n.t('offer_line.danger.nocive') })
    if (report.danger_icons.includes(DANGER_ICONS.TOXIC))       icons.push({ icon: toxicImg,        content: i18n.t('offer_line.danger.toxic') })
    if (report.danger_icons.includes(DANGER_ICONS.HEALTH))      icons.push({ icon: healthImg,       content: i18n.t('offer_line.danger.health') })
    if (report.danger_icons.includes(DANGER_ICONS.ENVIRONMENT)) icons.push({ icon: environementImg, content: i18n.t('offer_line.danger.environment') })
    return icons
  }, [report.danger_icons])

  const layout = useMemo(() => {
    // Mobile versions
    switch(view) {
      case CONSTANTS.VIEW.VIEW_INFO:    return <ReportDocuments />
      case CONSTANTS.VIEW.VIEW_CHAT:    return <Chat
        report        = {report}
        updateMethods = {updateMethods}
        height        = '45dvh'
      />
      case CONSTANTS.VIEW.VIEW_TASKS:   return <ReportTask        overflow='visible' />
      case CONSTANTS.VIEW.VIEW_COSTS:   return <ReportCosts       overflow='visible' />
      case CONSTANTS.VIEW.VIEW_REMARKS: return <ReportMaintenance overflow='visible' />
    }
  }, [report, isDesktop, view])

  if (!report || !report.status) return(<InfoStyle.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></InfoStyle.Loader>)
  if (loading) return(<InfoStyle.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></InfoStyle.Loader>)

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return <>
    <InfoStyle.InfoHeader>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {infoHistory.length > 1
            ? <InfoStyle.BackIcon onClick={historyBack}>
              <FontAwesomeIcon icon="caret-left" />
            </InfoStyle.BackIcon>
            : <div></div>
          }
          <Tag
            icon       = {<FontAwesomeIcon icon="circle-info" />}
            background = 'var(--rep-neutral-light)'
            color      = 'var(--rep-neutral-primary)'
          >
            {i18n.t('report.report')}
          </Tag>
          <Tag
          // background     = {`${REPORT.STATUS_COLOR[report.status]}33`}
            color          = {REPORT.STATUS_COLOR[report.status]}
            icon           = {<FontAwesomeIcon icon={REPORT.STATUS_ICON[report.status]} fade={report.status === REPORT.STATUS.ONGOING} />}
            tooltipContent = {i18n.t('report.report_status')}
          >
            {i18n.t(`report.status.${report.status}`)}
          </Tag>
          {!!report.urgent &&
            <Tag
              background     = 'var(--rep-danger)'
              border         = 'var(--rep-danger)'
              color          = 'white'
              icon           = {<FontAwesomeIcon icon="land-mine-on" size="sm"/>}
              tooltipContent = {i18n.t('report.urgent')}
            >
              {i18n.t('report.urgent')}
            </Tag>
          }
        </div>
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button
            icon   = {<FontAwesomeIcon icon="arrow-up-right-from-square" />}
            // border = 'var(--rep-warning)'
            color  = 'var(--rep-warning)'
            hover  = 'var(--rep-warning-light)'
            click  = {() => window.open(`/reports/${report.id}`, '_blank')}
            align  = 'center'
          >
            {i18n.t('actions.see')}
          </Button>

          <InfoStyle.Header>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '8px' }}>
              {report.archived &&
                <Tag
                  icon       = {<FontAwesomeIcon icon="folder" />}
                  background = "var(--rep-warning-light)"
                  color      = "var(--rep-warning)"
                >
                    Archived
                </Tag>
              }
            </div>
          </InfoStyle.Header>

          <InfoStyle.CloseIcon onClick={closeInfoWindow}>
            <FontAwesomeIcon icon="times" />
          </InfoStyle.CloseIcon>
        </div>
      </div>
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <InfoStyle.Header>
            <InfoStyle.TitleActionsContainer>
              {report.title}
            </InfoStyle.TitleActionsContainer>
          </InfoStyle.Header>
          {!!actions.length &&
          <Dropdown
            options = {actions}
            icon    = {<FontAwesomeIcon icon="bars" />}
            hover   = 'var(--rep-primary-light)'
            size    = 'none'
          >
            {i18n.t('actions.other_actions')}
          </Dropdown>
          }
        </div>
      </div>
      <InfoStyle.HeaderActions>
        {!!report.waste_category &&
        <Tag
          icon		       = {<FontAwesomeIcon icon="dumpster" />}
          color          = 'var(--rep-primary)'
          border         = 'var(--rep-primary)'
          tooltipContent = {i18n.t('waste_category.waste_category')}
        >
          {report.waste_category.description}
        </Tag>
        }
        {!!report.unit &&
          <Tag
            click          = {() => openDetails(report.unit, 'units') }
            icon           = {<FontAwesomeIcon icon="map-pin" />}
            hover          = 'var(--rep-neutral-primary-light)'
            // background     = 'var(--rep-neutral-light)'
            color          = 'var(--rep-neutral-primary)'
            tooltipTitle   = {i18n.t('unit.unit')}
            tooltipContent = {report.unit.path_string}
          >
            {report.unit.name}
          </Tag>
        }
        {!!report.amenity &&
          <Tag
            click          = {() => openDetails(report.amenity, 'amenities')}
            icon           = {<FontAwesomeIcon icon="gear" />}
            hover          = 'var(--rep-neutral-primary-light)'
            // background     = 'var(--rep-neutral-light)'
            color          = 'var(--rep-neutral-primary)'
            tooltipTitle   = {i18n.t('amenity.amenity')}
            tooltipContent = {report.amenity.path_string}
          >
            {report.amenity.name}
          </Tag>
        }
        {!!report.deadline && report.status !== REPORT.STATUS.CANCELED &&
          <Tag
            icon           = {report.deadline_delta >= 0 ? <FontAwesomeIcon icon="calendar-day" /> : <FontAwesomeIcon icon="triangle-exclamation" />}
            color          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
            // background     = {report.deadline_delta >= 0 ? 'var(--rep-neutral-light)' : 'var(--rep-danger-light)'}
            // hover          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary-light)' : 'var(--rep-danger-light)'}
            hover          = 'transparent'
            tooltipContent = {report.status !== REPORT.STATUS.CLOSED ? report.deadline_string :  i18n.t('report.deadline')}
            tooltipTitle   = {report.status !== REPORT.STATUS.CLOSED && i18n.t('report.deadline')}
          >
            {new Date(report.deadline).toLocaleDateString(i18n.locale)}
          </Tag>
        }
      </InfoStyle.HeaderActions>
    </InfoStyle.InfoHeader>


    <Expander expanded={expanded} setExpanded={setExpanded}>


      <InfoStyle.TextWithFormatting>
        {report.description}
      </InfoStyle.TextWithFormatting>


      {!!report.priority &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.priority')}
          </InfoStyle.InfoLineTitle>
          <Tag
            icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
            color          = 'var(--rep-neutral-primary)'
            background     = 'var(--rep-neutral-light)'
            hover          = 'var(--rep-neutral-primary-light)'
            tooltipContent = {i18n.t('report.priority')}
          >
            {report.priority.name}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {!!report.report_number &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.report_number')}
          </InfoStyle.InfoLineTitle>
          <Tag
            icon           = {<FontAwesomeIcon icon="hashtag" />}
            color          = 'var(--rep-neutral-primary)'
            // background     = 'var(--rep-neutral-light)'
            background     = 'transparent'
            border         = 'white'
          >
            {report.report_number}
          </Tag>
          <Tag
            icon           = {<FontAwesomeIcon icon="copy" />}
            color          = "var(--rep-primary)"
            click          = {() => navigator.clipboard.writeText(report.report_number)}
            tooltipContent = {i18n.t('report.actions.copy_number')}
            iconAnimateClick

          />
        </InfoStyle.InfoLine>
      }

      {!!report.type &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.report_type')}
          </InfoStyle.InfoLineTitle>
          <Tag
            tooltipContent = {report.type.name}
            icon           = {<FontAwesomeIcon icon={report.type.logo || 'triangle-exclamation'} />}
            color          = {report.type.color}
            background     = {`${report.type.color}20`}
          >
            {report.type.name}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {!!report.drop_off &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.drop_off')}
          </InfoStyle.InfoLineTitle>
          <Tag
            tooltipContent = {i18n.t('report.drop_off')}
            color          = 'var(--rep-neutral-primary)'
            background     = 'transparent'
          >
            {report.drop_off}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {report.maintenance_plan &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('maintenance.maintenance_plan')}
          </InfoStyle.InfoLineTitle>
          <Tag
            // icon           = {<FontAwesomeIcon icon="oil-can" />}
            // hover          = 'var(--rep-primary-light)'
            color          = 'var(--rep-neutral-primary)'
            tooltipContent = {report.maintenance_plan.sentence}
          >
            {report.maintenance_plan.name}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {!!report.danger_icons.length &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.danger')}
          </InfoStyle.InfoLineTitle>
          {report.type.hh_name === CONSTANTS.REPORT.TYPE.RECYCLE && dangerIcons.map((danger, key) =>
            <WithTooltip content={danger.content} key={key}>
              <img src={danger.icon} height='28px' />
            </WithTooltip> )
          }
        </InfoStyle.InfoLine>
      }

      {!!report.category &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('expertise.expertise')}
          </InfoStyle.InfoLineTitle>
          <Tag
            color          = 'var(--rep-neutral-primary)'
            background     = 'var(--rep-neutral-light)'
            hover          = 'var(--rep-neutral-primary-light)'
            tooltipContent = {i18n.t('expertise.expertise')}
          >
            {report.category.name}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {!!report.created_by &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.created_by')}
          </InfoStyle.InfoLineTitle>
          <Avatar
            firstName      = {report.created_by.first_name}
            lastName       = {report.created_by.last_name}
            background     = 'var(--rep-primary-light)'
            color          = 'var(--rep-primary)'
            border         = 'white'
            title          =  {i18n.t('shared.created_by')}
            tooltipContent =  {i18n.t('report.created_by_on', { date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.created_at)), user: `${report.created_by?.first_name} ${report.created_by?.last_name}` })}
          />
        </InfoStyle.InfoLine>
      }

      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {i18n.t('report.created_at')}
        </InfoStyle.InfoLineTitle>
        <Tag
          // icon		       = {<FontAwesomeIcon icon="file-pen" />}
          color          = 'var(--rep-neutral-primary)'
          background     = 'transparent'
          // background     = 'var(--rep-neutral-light)'
          // hover          = 'var(--rep-neutral-primary-light)'
          tooltipContent = {i18n.t('report.created_at')}
        >
          {new Date(report.created_at).toLocaleDateString(i18n.locale)}
        </Tag>
      </InfoStyle.InfoLine>

      {!!report.requester &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.requested_by')}
          </InfoStyle.InfoLineTitle>
          <Avatar
            firstName      = {report.requester.first_name}
            lastName       = {report.requester.last_name}
            background     = {report.requester?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
            color          = {report.requester?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
            // background     = 'var(--rep-neutral-light)'
            // color          = 'var(--rep-neutral)'
            border         = 'white'
            title          =  {i18n.t('shared.requested_by')}
            tooltipContent =  {<>
              <div>
                {`${report.requester?.first_name} ${report.requester?.last_name}` }
              </div>
              {report.requester?.phone &&
                <div>
                  <FontAwesomeIcon icon="phone" /> {report.requester?.phone}
                </div>
              }
            </>}
          />
        </InfoStyle.InfoLine>
      }

      {!!report.deadline &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.deadline')}
          </InfoStyle.InfoLineTitle>
          <Tag
            // icon		       = {<FontAwesomeIcon icon="calendar-plus" />}
            color          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
            background     = {report.deadline_delta >= 0 ? 'transparent' : 'var(--rep-danger-light)'}
            // background     = 'transparent'
            // hover          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary-light)' : 'var(--rep-danger-light)'}
            tooltipContent = {report.status !== REPORT.STATUS.CLOSED ? report.deadline_string : i18n.t('report.deadline')}
            tooltipTitle   = {report.status !== REPORT.STATUS.CLOSED && i18n.t('report.deadline')}
          >
            {new Date(report.deadline).toLocaleDateString(i18n.locale)}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {!!report.responsible &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.responsible')}
          </InfoStyle.InfoLineTitle>
          <Avatar
            firstName      = {report.responsible.first_name}
            lastName       = {report.responsible.last_name}
            // background     = 'var(--rep-warning-light)'
            // color          = 'var(--rep-warning)'
            background     = {report.requester?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
            color          = {report.requester?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
            border         = 'white'
            title          =  {i18n.t('shared.responsible')}
          />
        </InfoStyle.InfoLine>
      }

      {!!report.expected_date &&
      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {i18n.t('report.expected_date')}
        </InfoStyle.InfoLineTitle>
        <Tag
          // icon		       = {<FontAwesomeIcon icon="calendar-plus" />}
          color          = {report.expected_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
          background     = {report.expected_delta >= 0 ? 'transparent' : 'var(--rep-danger-light)'}
          // background     = 'transparent'
          // hover          = {report.expected_delta >= 0 ? 'var(--rep-neutral-primary-light)' : 'var(--rep-danger-light)'}
          tooltipContent = {report.status !== REPORT.STATUS.CLOSED ? report.expected_string : i18n.t('report.expected_date')}
          tooltipTitle   = {report.status !== REPORT.STATUS.CLOSED && i18n.t('report.expected_date')}
        >
          {new Date(report.expected_date).toLocaleDateString(i18n.locale)}
        </Tag>
      </InfoStyle.InfoLine>
      }

      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {i18n.t('report.assigned_to')}
        </InfoStyle.InfoLineTitle>
        <AvatarList
          users      = {report.users}
          limit      = {3}
          title      = {i18n.t('report.assigned_to')}
          background = 'var(--rep-neutral-light)'
          color      = 'var(--rep-neutral)'
        />
      </InfoStyle.InfoLine>

      {!!report.done_at &&
      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {i18n.t('report.done_at')}
        </InfoStyle.InfoLineTitle>
        <Tag
          // icon		       = {<FontAwesomeIcon icon="clipboard-check" />}
          color          = {'var(--rep-neutral-primary)'}
          // background     = {'var(--rep-warning-light)'}
          background     = 'transparent'
          // hover          = {'var(--rep-warning-light)'}
          tooltipTitle   = {i18n.t('report.done_at')}
          tooltipContent = {report.done_string}
        >
          {new Date(report.done_at).toLocaleDateString(i18n.locale)}
        </Tag>
      </InfoStyle.InfoLine>
      }

      {!!report.validated_by && report.validated_date &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.validated_by')}
          </InfoStyle.InfoLineTitle>
          <Avatar
            firstName      = {report.validated_by.first_name}
            lastName       = {report.validated_by.last_name}
            // background     = 'var(--rep-success-light)'
            // color          = 'var(--rep-success)'
            background     = {report.requester?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
            color          = {report.requester?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
            border         = 'white'
            title          =  {i18n.t('shared.validated_by')}
          />
        </InfoStyle.InfoLine>
      }

      {!!report.ended_by &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.ended_by')}
          </InfoStyle.InfoLineTitle>
          <Avatar
            firstName      = {report.ended_by.first_name}
            lastName       = {report.ended_by.last_name}
            // background     = 'var(--rep-success-light)'
            // color          = 'var(--rep-success)'
            background     = {report.requester?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
            color          = {report.requester?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
            border         = 'white'
            title          =  {i18n.t('report.ended_by')}
          />
        </InfoStyle.InfoLine>
      }

      {!!report.ended_at &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('report.ended_at')}
          </InfoStyle.InfoLineTitle>
          <Tag
            // icon		       = {<FontAwesomeIcon icon="clipboard-check" />}
            color          = {'var(--rep-neutral-primary)'}
            // background     = {'var(--rep-warning-light)'}
            background     = 'transparent'
            // hover          = {'var(--rep-warning-light)'}
            tooltipTitle   = {i18n.t('report.ended_at')}
            tooltipContent = {report.done_string}
          >
            {new Date(report.ended_at).toLocaleDateString(i18n.locale)}
          </Tag>
        </InfoStyle.InfoLine>
      }

      {report.machine_stop &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('machine_stop.machine_state')}
          </InfoStyle.InfoLineTitle>
          <MachineStopTag machine_stop={report.machine_stop} equipment={report.amenity} updateMethods={updateMethods} />
        </InfoStyle.InfoLine>
      }

      {!!report.input_code &&
      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {i18n.t('charge_number.charge_number')}
        </InfoStyle.InfoLineTitle>
        <Tag
          color          = 'var(--rep-neutral-primary)'
          background     = 'var(--rep-neutral-light)'
          hover          = 'var(--rep-neutral-primary-light)'
          border         = 'white'
          tooltipContent = {i18n.t('charge_number.charge_number')}
        >
          {report.input_code.label}
        </Tag>
      </InfoStyle.InfoLine>
      }

      {!!report.remark?.id &&
      <InfoStyle.InfoLine isDesktop={isDesktop}>
        <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
          {report.remark.status === REMARK.STATUS.OPEN ? i18n.t('control_remarks.open_remark') : i18n.t('control_remarks.closed_remark')}
        </InfoStyle.InfoLineTitle>
        <Tag
          icon		       = {<FontAwesomeIcon icon="section" />}
          color          = {report.remark.status === REMARK.STATUS.OPEN ? 'var(--rep-warning)'                       : 'var(--rep-success)'}
          hover          = {report.remark.status === REMARK.STATUS.OPEN ? 'var(--rep-warning-light)'                 : 'var(--rep-success-light)'}
          tooltipContent = {report.remark.status === REMARK.STATUS.OPEN ? i18n.t('control_remarks.open_remark') : i18n.t('control_remarks.closed_remark')}
          click          = {() => openDetails(report.remark.report, 'reports')}
        >
          {report.remark.description}
        </Tag>
      </InfoStyle.InfoLine>
      }

      {!!report.custom_fields.length &&
        report.custom_fields.map(field =>
          <InfoStyle.InfoLine isDesktop={isDesktop} key={field.id}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {field.name}
            </InfoStyle.InfoLineTitle>
            <FieldTag
              key       = {field.id}
              field     = {field}
              // value     = {report.custom_fields.find(cf => cf.field.id === field.id)?.value}
              renderTag = {false}
            />
          </InfoStyle.InfoLine>
        )
      }
    </Expander>


    {[REPORT.STATUS.CLOSED, REPORT.STATUS.CANCELED].includes(report.status) &&
        <HeaderStyles.CalloutStyles>
          <Callout
            title       = {
              report.status === REPORT.STATUS.CANCELED
                ? i18n.t('report.canceled_on_by', { date: new Date(report.canceled_at).toLocaleDateString(i18n.locale, options), user: `${report.canceled_by?.first_name} ${report.canceled_by?.last_name}` })
                : report.ended_by
                  ? i18n.t('report.closed_on_by', { date: new Date(report.ended_at).toLocaleDateString(i18n.locale, options), user: `${report.ended_by?.first_name} ${report.ended_by?.last_name}` })
                  : i18n.t('report.closed_on', { date: new Date(report.ended_at).toLocaleDateString(i18n.locale, options)})
            }
            border      = 'none'
            background  = {report.status === REPORT.STATUS.CLOSED ? 'var(--rep-success-light)' : 'var(--rep-danger-light)'}
            color       = {report.status === REPORT.STATUS.CLOSED ? 'var(--rep-success)' : 'var(--rep-danger)'}
            icon        = {<FontAwesomeIcon icon="lock" />}
            marginY     = 'S'
          >
            {report.closing_comment ? report.closing_comment : i18n.t('report.no_closing_comment')}
          </Callout>
        </HeaderStyles.CalloutStyles>
    }
    {report.archived &&
      <HeaderStyles.CalloutStyles>
        <Callout
          title       = {i18n.t('report.archived')}
          border      = 'none'
          background  = {'var(--rep-warning-light)'}
          color       = {'var(--rep-warning)'}
          icon        = {<FontAwesomeIcon icon="folder-open" />}
          marginY     = 'S'
        >
          {i18n.t('report.report_archived')}
        </Callout>
      </HeaderStyles.CalloutStyles>
    }

    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0', gap: '8px', flexWrap: 'wrap' }}>
      {managerActions}
    </div>

    <div style={{ height: 'fit-content'}}>
      <Switch
        name     = 'mobile-switch'
        options  = {switchOptions || []}
        selected = {view}
        callback = {changeView}
        marginY  = 'M'
      />
    </div>
    <div ref={containerRef}>
      {layout}
    </div>
  </>
}

export default InfoReport
