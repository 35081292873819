import React, { useEffect, useMemo, useReducer, useState }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion              from '@components/Accordion'
import AddEquipmentToContract from '@components/Contract/Link'
import Avatar                 from '@components/Avatar'
import AvatarList             from '@components/AvatarList'
import Button                 from '@components/Button'
import ContractCard           from '@components/Contract'
import ContractForm           from '@components/Contract/Form'
import Dropdown               from '@components/Dropdown'
import Expander               from '@components/Expander'
import FavouriteProviderForm  from '@components/FavouriteProvider/Form'
import FieldTag               from '@components/Form/FieldTag'
import { FileGrid }           from '@components/Form/FileUploader/Thumbnail/style'
import FileUploader           from '@components/Form/FileUploader'
import MachineStopTag         from '@components/MachineStop/MachineStopTag'
import MaintenanceCard        from '@components/Maintenance/MaintenanceCard'
// import QRCode                 from '@components/QRCode/QRCode'
import ReportCard             from '@components/Report/ReportCard'
import ReportForm             from '@components/Report/Form'
import SelectUserForm         from '@components/User/SelectUserForm'
import Tag                    from '@components/Tag'
import Thumbnail              from '@components/Form/FileUploader/Thumbnail'
import Tree                   from '@components/Tree'
import UnitForm               from '@components/Unit/Form'

import AddEquipmentToPlan from '@pages/Maintenances/Form/AddEquipmentToPlan'
import PlanForm           from '@pages/Maintenances/Form/PlanForm'

import { MaintenancesContextProvider } from '@context/MaintenancesContext'
import { useGlobalContextState }       from '@context/GlobalContext'
import { useUnitContextState }         from '@context/UnitContext'

// import { buildFilterString } from '@utils/filterString'

import { CONTRACT_ACTIONS, contractReducer } from '@reducers/contractReducer'
import { UNIT_ACTIONS, unitReducer }         from '@reducers/unitReducer'

import * as InfoStyle from '@components/InfoWindow/style'
import * as Style     from '../../style'

const InfoUnit = ({
  readOnly,
  fromModal,
  sidebarWidth,
  infoHistory,
  historyBack,
  closeInfoWindow
}) => {

  const {
    fetchApi,
    i18n,
    isDesktop,
    showModal,
    closeModal,
    current_user,
    current_company,
    showAlert,
    closeAlert,
    CONSTANTS
  } = useGlobalContextState()

  const { unit, setUnit, updateMethods } = useUnitContextState()

  const [expanded, setExpanded] = useState(false)
  // const [showQR,   setShowQR]   = useState(false)
  const [loading,  setLoading]  = useState(true)

  const [ongoingReports,    setOngoingReports]    = useState([])
  const [ongoingPagination, setOngoingPagination] = useState({})
  const [ongoingLoading,    setOngoingLoading] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    urlParams.set('info_type', 'units')
    urlParams.set('info_id',    unit.id)

    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
  }, [unit])

  const params = useMemo(() => ({
    children:            true,
    ancestors:           true,
    path:                true,
    tickets:             true,
    documents:           true,
    maintenances:        true,
    contracts:           true,
    employees:           true,
    responsibles:        true,
    favourite_providers: true,
    invoice_contact:     true,
    custom_fields:       true,
    machine_stop:        true,
    permissions:         true,
  }), [])

  const [_state, dispatch] = useReducer(unitReducer, { unit, fetchApi, closeModal, updateMethods })
  const [_contractState, contractDispatch] = useReducer(contractReducer, { fetchApi, closeModal, updateMethods })

  const fetchUnit  = ()   => dispatch({ type: UNIT_ACTIONS.FETCH,  unit, params, callbacks: [() => setLoading(false)] })
  const createUnit = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.CREATE, unit, data, fieldset, callbacks: [closeModal] })
  const updateUnit = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.UPDATE, unit, params, data, fieldset, callbacks: [fetchUnit, closeModal] })
  const archive    = ()   => dispatch({ type: UNIT_ACTIONS.ARCHIVE, unit, callbacks: [fetchUnit, closeAlert] })
  const unarchive  = ()   => dispatch({ type: UNIT_ACTIONS.UNARCHIVE, unit, callbacks: [fetchUnit] })

  const addUsers        = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.ADD_USERS, unit, data, params, fieldset, callbacks: [fetchUnit, closeModal] })
  const addFavourite    = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.ADD_FAVOURITE, unit, data, params, fieldset, callbacks: [fetchUnit, closeModal] })
  const editFavourite   = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.EDIT_FAVOURITE, unit, data, params, fieldset, callbacks: [fetchUnit, closeModal] })
  const removeFavourite = data => dispatch({ type: UNIT_ACTIONS.REMOVE_FAVOURITE, unit, data, params, callbacks: [fetchUnit, closeModal] })
  const addToPlan       = (data, fieldset) => dispatch({ type: UNIT_ACTIONS.ADD_TO_PLAN, unit, data, fieldset, callbacks: [fetchUnit, closeModal]})

  const createContract  = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.CREATE, data: { contract: data }, fieldset, callbacks: [fetchUnit, closeModal]})
  const updateContract  = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.UPDATE, data, fieldset, callbacks: [fetchUnit, closeModal]})
  const addToContract   = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.LINK, data: { id: data.contract, unit_id: unit.id }, fieldset, callbacks: [fetchUnit, closeModal]})
  const unlinkContract  = contract         => contractDispatch({ type: CONTRACT_ACTIONS.UNLINK, data: { unit_id: unit.id, id: contract.id }, callbacks: [fetchUnit]})

  // TODO BUG Editing planned end date is multiplying workperiods

  const fetchOngoingReports = page => {
    setOngoingLoading(true)
    dispatch({
      type:       UNIT_ACTIONS.FETCH_ONGOING_REPORTS,
      unit,
      page,
      pagination: ongoingPagination,
      callbacks:  [
        data => setOngoingReports(ongoing => [...ongoing, ...data.reports]),
        data => setOngoingPagination(data.pagination),
        () => setOngoingLoading(false)
      ]})
  }

  const resetOngoingReports = () => {
    setOngoingReports([])
    setOngoingPagination({})
  }

  useEffect(() => {
    setLoading(true)
    fetchUnit()
    setOngoingReports([])
    setOngoingPagination({})
  }, [unit.id])

  const actions = useMemo(() => {
    const resultActions = []

    if (readOnly || !unit?.permissions) return resultActions

    if (unit.permissions.can_update_unit)
      resultActions.push({
        icon:      <FontAwesomeIcon icon="pen" />,
        color:     'var(--rep-primary)',
        content:   i18n.t('actions.edit'),
        separator: true,
        click:     () => showModal({
          title:   i18n.t('unit.actions.edit'),
          content: <UnitForm
            unit       = {unit}
            createUnit = {createUnit}
            updateUnit = {updateUnit}
          />,
        })
      })

    if (unit.permissions.can_archive_unit) resultActions.push({
      icon:    <FontAwesomeIcon icon="folder" />,
      color:   'var(--rep-warning)',
      content: i18n.t('actions.archive'),
      click:   () => {
        showAlert(
          i18n.t('amenity.actions.archive_alert'),
          [{
            icon:       <FontAwesomeIcon icon="folder" />,
            click:      archive,
            background: 'var(--rep-warning)',
            color:      'white',
            content:    i18n.t('unit.actions.archive')
          }]
        )
      }
    })

    if (unit.permissions.can_unarchive_unit) resultActions.push({
      icon:    <FontAwesomeIcon icon="folder-open" />,
      color:   'var(--rep-warning)',
      content: i18n.t('actions.unarchive'),
      click:   unarchive
    })

    // resultActions.push({
    //   icon:    <FontAwesomeIcon icon="qrcode" />,
    //   color:   'var(--rep-accent)',
    //   content: i18n.t('shared.qr_code'),
    //   click:   () => setShowQR(showQR => !showQR)
    // })

    return resultActions
  }, [unit])

  if (!unit)   return(<Style.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></Style.Loader>)
  if (loading) return(<Style.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></Style.Loader>)

  return (
    <>
      <Style.InfoHeader width={sidebarWidth} isDesktop={isDesktop}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {infoHistory.length > 1
              ? <Style.BackIcon onClick={historyBack}>
                <FontAwesomeIcon icon="caret-left" />
              </Style.BackIcon>
              : <div></div>
            }

            <Tag
              icon       = {<FontAwesomeIcon icon="circle-info" />}
              background = 'var(--rep-neutral-light)'
              color      = 'var(--rep-neutral-primary)'
            >
              {i18n.t('unit.unit')}
            </Tag>
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            {!readOnly && unit.permissions.can_create_report &&
              <Button
                icon   = {<FontAwesomeIcon icon="plus" />}
                // border = 'var(--rep-primary)'
                color  = 'var(--rep-primary)'
                hover  = 'var(--rep-primary-light)'
                alingSelf='center'
                click  = {() => showModal({
                  title:   i18n.t('report.actions.open_ticket'),
                  content: <ReportForm updateMethods={{}} report={{ unit: unit }} />
                })}
              >
                {i18n.t('report.report')}
              </Button>
            }
            <Button
              icon   = {<FontAwesomeIcon icon="arrow-up-right-from-square" />}
              // border = 'var(--rep-warning)'
              color  = 'var(--rep-warning)'
              hover  = 'var(--rep-warning-light)'
              click  = {() => window.open(`/units/${unit.id}`, '_blank')}
              alingSelf='center'
            >
              {i18n.t('actions.see')}
            </Button>

            <InfoStyle.Header archived={unit.archived} danger={unit?.machine_stop?.status === CONSTANTS.MACHINE_STOP.STATUS.STOPPED}>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '8px' }}>
                {unit.archived &&
                  <Tag
                    icon       = {<FontAwesomeIcon icon="folder" />}
                    background = "var(--rep-warning-light)"
                    color      = "var(--rep-warning)"
                  >
                    {i18n.t('shared.archived')}
                  </Tag>
                }

              </div>
            </InfoStyle.Header>

            <Style.CloseIcon onClick={closeInfoWindow}>
              <FontAwesomeIcon icon="times" />
            </Style.CloseIcon>

          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <InfoStyle.Header archived={unit.archived} danger={unit?.machine_stop?.status === CONSTANTS.MACHINE_STOP.STATUS.STOPPED}>
            <InfoStyle.TitleActionsContainer>
              {unit.name}
            </InfoStyle.TitleActionsContainer>
          </InfoStyle.Header>
          {!!actions.length &&
            <Dropdown
              options = {actions}
              icon    = {<FontAwesomeIcon icon="bars" />}
              hover   = 'var(--rep-primary-light)'
            >
              {i18n.t('actions.other_actions')}
            </Dropdown>
          }
        </div>
      </Style.InfoHeader>


      {/* {showQR &&
            <QRCode
              name    = {unit.path_string}
              path    = {unit.qrcode_path}
              closeQr = {() => setShowQr(false)}
            />
      } */}
      <Expander expanded={expanded} setExpanded={setExpanded}>

        <div style={{ margin: '8px 0'}}>
          {!!unit.description && unit.description}
        </div>

        {/* {unit?.permissions?.can_use_machine_stops &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.status')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <MachineStopTag
              machine_stop  = {unit?.machine_stop}
              equipment     = {unit}
              updateMethods = {updateMethods}
              readOnly      = {readOnly}
              alignSelf = 'center'
            />
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        } */}

        {!!unit.alt_name &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('unit.alt_name')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              {unit.alt_name}
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('unit.managers')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {unit.responsibles?.length
              ? <AvatarList
                users      = {unit.responsibles}
                background = 'var(--rep-warning-light)'
                color      = 'var(--rep-warning)'
              />
              : <div></div>
            }
            {!readOnly && unit.permissions.can_change_responsible &&
            <Tag
              icon           = {<FontAwesomeIcon icon="user-pen" />}
              tooltipContent = {i18n.t('shared.actions.change_responsible')}
              // border         = 'var(--rep-primary)'
              hover          = 'var(--rep-primary-light)'
              color          = 'var(--rep-primary)'
              click = {() => showModal({
                title:   i18n.t('shared.actions.change_responsible'),
                content: <SelectUserForm
                  equipment     = {unit}
                  type          = 'responsible'
                  callback      = {addUsers}
                  filters       = {['managers']}
                  defaultFilter = {'managers'}
                  multiselect
                />
              })}
            />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('unit.default_technician')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {unit.technician
              ? <Avatar
                firstName  = {unit.technician.first_name}
                lastName   = {unit.technician.last_name}
                background = 'var(--rep-primary-light)'
                color      = 'var(--rep-primary)'
                border     = 'white'
                title      = {i18n.t('unit.default_technician')}
              />
              : <div></div>
            }
            {!readOnly && unit.permissions.can_change_default_tech &&
            <Tag
              icon           = {<FontAwesomeIcon icon="user-pen" />}
              tooltipContent = 'Change default tech'
              // border         = 'var(--rep-primary)'
              hover          = 'var(--rep-primary-light)'
              color          = 'var(--rep-primary)'
              click          = {() => showModal({
                title:   i18n.t('shared.actions.change_default'),
                content: <SelectUserForm
                  equipment = {unit}
                  type      = 'default_tech'
                  callback  = {addUsers}
                />
              })}
            />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('favourite_provider.favourite_providers')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <div style={{display: 'flex', gap: '4px'}}>
              {unit.favourite_providers.map(favourite =>
                <Dropdown
                  key     = {favourite.id}
                  options = {[
                    { content: i18n.t('actions.edit'),
                      icon:    <FontAwesomeIcon icon="pen" />,
                      color:   'var(--rep-primary)',
                      click:   () => showModal({
                        title:   'Edit favourite provider',
                        content: <FavouriteProviderForm
                          equipment = {unit}
                          favourite  ={favourite}
                          callback  = {editFavourite}
                        />
                      })},
                    {
                      content: i18n.t('actions.delete'),
                      color:   'var(--rep-danger)',
                      icon:    <FontAwesomeIcon icon="trash" />,
                      click:   () => removeFavourite(favourite)
                    },
                  ]}
                >
                  <Avatar
                    firstName      = {favourite.favourite_user.first_name}
                    lastName       = {favourite.favourite_user.last_name}
                    background     = {favourite.service_provider ? 'var(--rep-accent-light)' : 'var(--rep-primary-light)'}
                    color          = {favourite.service_provider ? 'var(--rep-accent)' : 'var(--rep-primary)'}
                    title          = {`${favourite.favourite_user.name} [${i18n.t(favourite.service_provider ? 'favourite_provider.external_prov' :'favourite_provider.internal_prov')}]`}
                    tooltipContent = {favourite.expertise.name}
                    overlapping
                  />
                </Dropdown>
              )}
            </div>

            {!readOnly && unit.permissions.can_change_favourites &&
            <Tag
              icon           = {<FontAwesomeIcon icon="heart-circle-plus" />}
              tooltipContent = {i18n.t('unit.actions.add_favourite')}
              // border         = 'var(--rep-primary)'
              hover          = 'var(--rep-primary-light)'
              color          = 'var(--rep-primary)'
              click          = {() => showModal({
                title:   i18n.t('unit.actions.add_favourite'),
                content: <FavouriteProviderForm
                  equipment = {unit}
                  callback  = {addFavourite}
                />
              })}
            />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('user.users')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {unit.employees.length
              ? <AvatarList
                users      = {unit.employees}
                background = 'var(--rep-neutral-light)'
                color      = 'var(--rep-neutral)'
              />
              : <div></div>
            }
            {!readOnly && unit.permissions.can_change_employees &&
            <Tag
              icon           = {<FontAwesomeIcon icon="user-pen" />}
              tooltipContent = {i18n.t('unit.actions.change_employees')}
              // border         = 'var(--rep-primary)'
              hover          = 'var(--rep-primary-light)'
              color          = 'var(--rep-primary)'
              click          = {() => showModal({
                title:   i18n.t('unit.actions.change_employees'),
                content: <SelectUserForm
                  equipment     = {unit}
                  type     = 'employee'
                  callback = {addUsers}
                  multiselect
                />
              })}
            />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        {!!unit.unit_owner &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('unit.unit_owner')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              {unit.unit_owner}
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        {!!unit.terrain_owner &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('unit.terrain_owner')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              {unit.terrain_owner}
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        {!!unit.cadastre &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('unit.cadastre')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              {unit.cadastre}
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        {!!unit.transport_cost &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('unit.transport')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              {unit.transport_cost} €
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        {!!unit.invoice_contact &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('invoice_contact.invoice_contact')}
            </InfoStyle.InfoLineTitle>
            <InfoStyle.InfoLineContent>
              <Tag
                icon           = {<FontAwesomeIcon icon="receipt" />}
                color          = 'var(--rep-primary)'
                background     = 'var(--rep-primary-light)'
                tooltipTitle   = {i18n.t('shared.vat')}
                tooltipContent = {unit.invoice_contact.vat_nb}
              >
                {unit.invoice_contact.name}
              </Tag>
            </InfoStyle.InfoLineContent>
          </InfoStyle.InfoLine>
        }

        {unit.full_address &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.address')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <Button
              // icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
              color          = 'var(--rep-primary)'
              // background     = 'var(--rep-light)'
              hover          = 'var(--rep-primary-light)'
              // tooltipContent = {i18n.t('report.priority')}
              click  = {() => window.open(`https://www.google.com/maps/place/${unit.full_address}`, '_blank')}
            >
              {unit.full_address}
            </Button>
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        }

        {!!unit.custom_fields.length &&
        unit.custom_fields.map(field =>
          <InfoStyle.InfoLine isDesktop={isDesktop} key={field.id}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {field.name}
            </InfoStyle.InfoLineTitle>
            <FieldTag
              key       = {field.id}
              field     = {field}
              // value     = {report.custom_fields.find(cf => cf.field.id === field.id)?.value}
              renderTag = {false}
            />
          </InfoStyle.InfoLine>
        )
        }
      </Expander>

      {!!unit.reports_count &&
        <Accordion
          onToggle    = {() => fetchOngoingReports(1)}
          onClose     = {resetOngoingReports}
          border      = "transparent"
          header      = 'Ongoing tickets'
          counter     = {unit.reports_count}
          colorHeader = 'var(--rep-success)'
          icon        = {<FontAwesomeIcon icon="circle" color="var(--rep-success)" fade />}
          togglable
        >
          {ongoingReports.map(report => <ReportCard key={report.id} report={report} openInfo={!readOnly} fromModal={fromModal} />)}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {ongoingLoading
              ? <FontAwesomeIcon icon="spinner" spin />
              : (ongoingPagination.page !== ongoingPagination.last) &&
            <Button
              click={() => fetchOngoingReports(ongoingPagination.next)}
            >
              {i18n.t('shared.load_more')}
            </Button>
            }
          </div>
        </Accordion>
      }

      <Accordion
        header      = {i18n.t('shared.structure')}
        open        = {false}
        border      = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        icon        = {<FontAwesomeIcon icon="folder-tree" />}
        togglable
        // counter   = {unit.documents.length}
      >
        <Tree
          before           = {unit.ancestors}
          current          = {unit}
          after            = {[...unit.children, ...unit.amenities]}
          readOnly         = {readOnly}
          fromModal        = {fromModal}
          highlightedItems = {[unit]}
          showStatus       = {current_company.permissions.can_use_equipment_usages}
          showTickets
        />
      </Accordion>

      <Accordion
        header    = {i18n.t('maintenance.maintenance_list')}
        open      = {false}
        border    = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        counter   = {unit.maintenances.length}
        togglable = {!!unit.maintenances.length}
        icon      = {unit.maintenances.find(m => !m.archived && m.late) ? <FontAwesomeIcon icon="triangle-exclamation" color="var(--rep-danger)" /> : <FontAwesomeIcon icon="layer-group" />}
        action    = {!readOnly && <div style={{ display: 'flex', gap: '4px'}}>
          {unit.permissions.can_create_maintenance &&
          <Button
            icon={<FontAwesomeIcon icon="link" />}
            hover='var(--rep-primary-light)'
            click={() => showModal({
              title:   i18n.t('maintenance.actions.add_equipment_to_plan', {equipment: unit.name}),
              content: <AddEquipmentToPlan equipment={unit} addToPlan={addToPlan} />,
            })}
          >
            {i18n.t('maintenance.actions.add_to_plan')}
          </Button>
          }
          {unit.permissions.can_create_maintenance &&
          <Button
            icon={<FontAwesomeIcon icon="plus" />}
            hover='var(--rep-primary-light)'
            click={() => showModal({
              title:   i18n.t('maintenance.maintenance_plan'),
              content: <MaintenancesContextProvider current_user={current_user}>
                <PlanForm
                  current_user    = {current_user}
                  current_company = {current_company}
                  item            = {{ units: [{...unit, maintainable_id: unit.id, maintainable_name: unit.name, created: true }] }}
                  customCallbacks = {[fetchUnit, closeModal]}
                  fromRails
                />
              </MaintenancesContextProvider>,
              asWizard: true
            })}
          >
            {i18n.t('maintenance.actions.create_plan')}
          </Button>
          }
        </div>
        }
      >
        {unit.maintenances.map(maintenance =>
          <MaintenanceCard
            key               = {maintenance.id}
            initMaintenance   = {maintenance}
          />
        )}
      </Accordion>

      <Accordion
        header    = {i18n.t('document.documents')}
        open      = {false}
        counter   = {unit.documents.length || '0'}
        togglable = {!!unit.documents.length}
        border    = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        icon      = {<FontAwesomeIcon icon="file-zipper" />}
        action    = {!readOnly && unit.permissions.can_create_document &&
          <Button
            icon={<FontAwesomeIcon icon="plus" />}
            hover='var(--rep-primary-light)'
            click={() => showModal({
              title:   i18n.t('document.attach_file'),
              content: <FileUploader
                updateMethods = {updateMethods}
                objectType    = 'unit'
                objectId      = {unit.id}
                multiple
                closeModalAfter
                certification
              />
            })}
          >
            {i18n.t('shared.actions.add')}
          </Button>}
      >
        <FileGrid>
          {unit.documents.map(doc =>
            <Thumbnail
              key           = {doc.id}
              file          = {doc}
              updateMethods = {updateMethods}
              modaleDetails = {doc.permissions.can_update_document}
              closeModalAfter
            />
          )}
        </FileGrid>
      </Accordion>

      <Accordion
        header    = {i18n.t('contract.contracts')}
        open      = {false}
        counter   = {unit.contracts.length || '0'}
        togglable = {!!unit.contracts.length}
        border    = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        icon      = {<FontAwesomeIcon icon="file-contract" />}
        action    = {!readOnly && <div style={{ display: 'flex', gap: '4px'}}>
          {unit.permissions.can_link_equipment &&
          <Button
            icon={<FontAwesomeIcon icon="link" />}
            hover='var(--rep-primary-light)'
            click={() => showModal({
              title:   i18n.t('contract.actions.add_asset_to_existing', { equipment: unit.name }),
              content: <AddEquipmentToContract equipment={unit} addToContract={addToContract} />,
            })}
          >
            {i18n.t('contract.actions.link')}
          </Button>
          }
          {unit.permissions.can_create_contract &&
          <Button
            icon={<FontAwesomeIcon icon="plus" />}
            hover='var(--rep-primary-light)'
            click={() => showModal({
              title:   i18n.t('contract.actions.create_a_new'),
              content: <ContractForm
                unit           = {unit}
                createContract = {createContract}
              />
            })}
          >
            {i18n.t('contract.actions.create')}
          </Button>
          }
        </div>}
      >
        {unit.contracts.map(contract =>
          <ContractCard
            key      = {contract.id}
            contract = {contract}
            update   = {updateContract}
            unlink   = {unlinkContract}
          />
        )}
      </Accordion>
    </>
  )
}

export default InfoUnit
