import React, { useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'

import * as FormStyle from '@form/FormStyles'
import * as Style     from './style'

import Button               from '@components/Button'
import Callout              from '@components/Callout'
import CustomMaterialOption from './MaterialOption'
import Html5QrcodePlugin    from '@components/CodeReader'
import Input                from '@form/Input'
import Select               from '@form/Select'
import Tag                  from '@components/Tag'

import { IMaterialQuantity } from '@interfaces/index'

import { useGlobalContextState } from '@context/GlobalContext'

const MaterialOrderForm = ({
  withPrices,
  reportAmenity,
  revealQR = false
}) => {

  const { i18n, isDesktop, fetchApi } = useGlobalContextState()

  const [materialQuantities, setMaterialQuantities] = useState<IMaterialQuantity[]>([])
  const [category,           setCategory]           = useState(null)
  const [amenity,            _setAmenity]           = useState(reportAmenity || null)
  const [materialLocation,   setMaterialLocation]   = useState(null)
  const [showQr,             setShowQr]             = useState(revealQR)

  const [scannedProduct, setScannedProduct]        = useState(null)
  const [showLocationChoice, toggleLocationChoice] = useState(false)
  const [locationChoices, setLocationChoices]      = useState([])

  const setCategoryFilter         = category => setCategory(category?.object)
  const setMaterialLocationFilter = materialLocation => setMaterialLocation(materialLocation?.object)
  const addToList                 = materialQuantity => setMaterialQuantities([...materialQuantities, {...materialQuantity.object, cost_quantity: 1 }])

  const resultFromScan = data => {
    let material
    'object' in data ? material = data.object : material = data

    // if (material.material_quantities.length > 1) {
    setLocationChoices(material.material_quantities)
    setScannedProduct(material)
    toggleLocationChoice(true)
    // } else {
    //   selectLocation(material.material_quantities[0])
    // }
  }

  const total = useMemo(() => {
    return Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(materialQuantities.reduce((sum, matQuantity) => matQuantity.cost_quantity ? sum + (matQuantity.cost_quantity * matQuantity.material.price) : sum, 0))
  }, [materialQuantities])

  const removeFromList = materialQuantity => {
    const list = materialQuantities.filter(m => m.id !== materialQuantity.id)
    setMaterialQuantities(list)
  }

  const changeQuantity = (key, quantity) => {
    const materialQuantitiesList = [...materialQuantities]
    materialQuantitiesList[key].cost_quantity = Number.parseInt(quantity, 10)
    setMaterialQuantities(materialQuantitiesList)
  }

  const changePrice = (key, price) => {
    const materialQuantitiesList = [...materialQuantities]
    materialQuantitiesList[key].material.price = Number.parseFloat(price)
    setMaterialQuantities(materialQuantitiesList)
  }

  const fetchMaterial = scannedText => {
    if (scannedText.includes('/qr?ty=mat&qi=')) {
      const urlParams = new URLSearchParams(scannedText)
      const matQrId = urlParams.get('qi')
      fetchApi({
        url:      `/qr.json?ty=mat&qi=${matQrId}`,
        callback: resultFromScan
      })
    }
    else {
      fetchApi({
        url:      `/materials/search_by_barcode.json?barcode=${scannedText}`,
        callback: resultFromScan
      })
    }
  }

  const onNewScanResult = (decodedText, decodedResult) => {
    fetchMaterial(decodedText)
    setShowQr(false)
  }

  const selectLocation = selectedLocation => {
    // Result from scan should be a material_quantity
    setMaterialQuantities([...materialQuantities, { ...selectedLocation, cost_quantity: 1, material: scannedProduct} ])
    setScannedProduct(null)
    toggleLocationChoice(false)
    setLocationChoices([])
  }

  return (
    <Style.MaterialContainer>
      {!isDesktop && showQr &&
        <Html5QrcodePlugin
          fps                   = {10}
          qrbox                 = {250}
          disableFlip           = {false}
          qrCodeSuccessCallback = {onNewScanResult}
          facingMode            = "environment"
          setShowQrScanner      = {setShowQr}
        />
      }
      <FormStyle.Header><FontAwesomeIcon icon="cubes" />{i18n.t('material.actions.search_from_inventory')}</FormStyle.Header>
      <Select
        name          = 'category'
        label         = {i18n.t('material.product_categories')}
        searchUrl     = '/material_categories/search'
        callback      = {setCategoryFilter}
        emptyCallback = {setCategoryFilter}
        defaultValue  = {[category]}
        format        = {{content: 'name', value: 'id'}}
        marginY       = 'M'
        search
        cleanAfterSelect
        withEmpty
      />
      <Select
        name          = 'material_location'
        label         = {i18n.t('material_location.material_locations')}
        searchUrl     = '/material_locations/search'
        callback      = {setMaterialLocationFilter}
        emptyCallback = {setMaterialLocationFilter}
        defaultValue  = {[materialLocation]}
        format        = {{content: 'name', value: 'id'}}
        marginY       = 'M'
        search
        cleanAfterSelect
        withEmpty
      />
      <FormStyle.Group>
        <Select
          name    = 'materials_select'
          label   = {i18n.t('material.actions.select')}
          filters = {[
            {
              id:      'category',
              name:    i18n.t('shared.all'),
              filters: { category_id: category?.id || '', material_location_id: materialLocation?.id || '' }
            },
            {
              id:      'linked',
              name:    i18n.t('amenity.linked_materials'),
              filters: { category_id: category?.id || '', material_location_id: materialLocation?.id || '', amenity_id: amenity?.id || '' }
            }
          ]}
          search
          searchUrl     = '/material_quantities/search'
          callback      = {addToList}
          required      = {!materialQuantities.length}
          defaultValue  = {[]}
          customOption  = {CustomMaterialOption}
          format        = {{ content: 'material_name', details: 'material_brand', value: 'id' }}
          defaultFilter = 'category'
          marginY       = 'S'
          cleanAfterSelect
        />
      </FormStyle.Group>
      {!isDesktop &&
        <Button
          icon    = {<FontAwesomeIcon icon="qrcode" />}
          click   = {() => setShowQr(!showQr)}
          border  = 'var(--rep-primary)'
          marginY = 'M'
        >
          QR
        </Button>
      }


      {showLocationChoice &&
        <Callout
          background = 'transparent'
          title      = {i18n.t('material.actions.select_material_location', { material: scannedProduct.name })}
        >
          {locationChoices.map((choice, key) =>
            <div key={key}>
              <Button
                background = 'var(--rep-primary-light)'
                border     = 'var(--rep-primary)'
                click      = {() => selectLocation(choice)}
                marginY    = 'M'
              >
                {choice.location_name} <Style.Quantity>{choice.quantity}</Style.Quantity>
              </Button>
            </div>
          )}
        </Callout>
      }

      <input name='material_quantities' type='hidden' value={JSON.stringify(materialQuantities)} />

      {materialQuantities.map((mat, key) =>
        <Style.MaterialLine key={key}>
          <Style.MaterialDescription>
            <Style.MaterialBrand>
              {!!mat.material.internal_ref &&
                <Tag
                  icon        = {<FontAwesomeIcon icon='barcode' />}
                  border      = 'var(--rep-neutral)'
                  color       = 'var(--rep-neutral-primary)'
                >
                  {mat.material.internal_ref}
                </Tag>
              }
              {!!mat.material.category &&
                <Tag
                  icon        = {<FontAwesomeIcon icon='boxes-stacked' />}
                  color       = 'var(--rep-neutral-primary)'
                  border      = 'var(--rep-neutral)'
                >
                  {mat.material.category}
                </Tag>
              }
            </Style.MaterialBrand>

            <Style.MaterialNomination>
              <Style.MaterialName>{mat.material.name}</Style.MaterialName>
              {!!mat.material.brand &&
                <Style.MaterialBrand>
                  [{mat.material.brand}]
                </Style.MaterialBrand>
              }
            </Style.MaterialNomination>

            {!!mat.material.description && <Style.MaterialDescriptionDetails>{mat.material.description}</Style.MaterialDescriptionDetails>}
            {!!mat.material.packaging && <Style.MaterialDescriptionDetails><FontAwesomeIcon icon="temperature-low" /> {mat.material.packaging}</Style.MaterialDescriptionDetails>}
            {!!mat.location_name &&
              <Tag
                icon   = {<FontAwesomeIcon icon='location-dot' />}
                color  = 'var(--rep-neutral-primary)'
                border = 'var(--rep-neutral)'
              >
                {mat.location_name} — {i18n.t('material.in_stock', { quantity: mat.quantity })}
              </Tag>
            }
          </Style.MaterialDescription>

          <Style.MaterialQuantityInput>
            <Input
              name         = 'quantity'
              label        = {i18n.t('offer_line.quantity')}
              type         = 'number'
              defaultValue = {mat.cost_quantity || 1}
              change       = {(e) => changeQuantity(key, e.currentTarget.value)}
              marginY      = 'M'
            />
            {withPrices &&
            <>
              <Style.MaterialQuantityPrice>&#xd7;</Style.MaterialQuantityPrice>
              <Input
                name         = 'unit_price'
                label        = {i18n.t('offer_line.unit_price')}
                type         = 'number'
                defaultValue = {Number.parseFloat(mat.material.price.toString())}
                change       = {(e) => changePrice(key, e.currentTarget.value)}
                marginY      = 'M'
                decimals
              />
            </>
            }
            <Button
              click      = {() => removeFromList(mat)}
              color      = 'var(--rep-danger)'
              background = 'var(--rep-danger-light)'
              icon       = {<FontAwesomeIcon icon="times" />}>
              {i18n.t('actions.remove')}
            </Button>
          </Style.MaterialQuantityInput>
        </Style.MaterialLine>
      )}
      {withPrices &&
        <Tag
          icon       = {<FontAwesomeIcon icon="receipt" />}
          background = 'white'
          color      = 'var(--rep-success)'
          marginY    = 'M'
        >
          {i18n.t('shared.total')}: {total}
        </Tag>
      }
    </Style.MaterialContainer>
  )
}

export default MaterialOrderForm
