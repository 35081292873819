import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion     from '@components/Accordion'
import Avatar        from '@components/Avatar'
import Button        from '@components/Button'
import Callout       from '@components/Callout'
import Card          from '@components/Card'
import Chat          from '@components/Chat'
import CostForm      from '@components/Cost/Form'
import DoneForm      from './DoneForm'
import Dropdown      from '@components/Dropdown'
import FileUploader, { uploadFunction }  from '@form/FileUploader'
import FormQuestion  from '@components/FormQuestion'
import RequestForm   from '@components/Material/Request/RequestForm'
import Tag           from '@components/Tag'
import TaskForm      from './Form'
import Thumbnail     from '@form/FileUploader/Thumbnail'
import WithTooltip   from '@components/WithTooltip'
import WorkPeriod    from '@components/WorkPeriod'

import * as Style    from './style'

import useHumanMs from '@hooks/useHumanMs'

import { convertMsToMinutesSeconds } from '@utils/time'

import { TaskContextProvider }   from '@context/TaskContext'
import { useGlobalContextState } from '@context/GlobalContext'
import { useTaskContextState }   from '@context/TaskContext'

import { COST_ACTIONS, DOCUMENT_ACTIONS, MACHINE_ACTIONS, TASK_ACTIONS, costReducer, documentReducer, machineStopReducer, taskReducer } from '../../reducers'

import { TaskCardProps } from './types.d'
import toast from 'react-hot-toast'

const TaskCard: React.FC<TaskCardProps> = ({
  changeView,
  ongoingTask,
  setOngoingTask,
  ongoingRef
}) => {

  const {
    initAPI,
    fetchApi,
    showModal,
    closeModal,
    current_user,
    current_company,
    setInfoWindowProps,
    i18n,
    CONSTANTS,
  } = useGlobalContextState()

  const { TASK, COST, MACHINE_STOP } = CONSTANTS

  const [animation, setAnimation] = useState(null)
  const [startedAt, setStartedAt] = useState(Date.now())
  const [timer,     setTimer]     = useState(Date.now() - new Date(startedAt).getTime())
  const [opened,    setOpened]    = useState(false)

  const taskRef = useRef(null)

  const { task, setTask, updateMethods } = useTaskContextState()

  const API = initAPI({
    reportId: task.report.id,
    taskId:   task.id,
    updateMethods
  })

  const [notifCount,    setNotifCount]   = useState(task.report.notification_count)

  const [_state,        dispatch]        = useReducer(taskReducer,        { task, API, updateMethods, setAnimation, fetchApi, closeModal })
  const [_costState,    costDispatch]    = useReducer(costReducer,        { task, report: task.report, initAPI, updateMethods, setAnimation, fetchApi, closeModal })
  const [_machineState, machineDispatch] = useReducer(machineStopReducer, { task, stop: task.report.machine_stop, updateMethods, fetchApi })

  const machineReady     = () => machineDispatch({ type: MACHINE_ACTIONS.READY   })
  const machineRestop    = () => machineDispatch({ type: MACHINE_ACTIONS.RESTOP  })
  const machineRestarted = () => machineDispatch({ type: MACHINE_ACTIONS.RESTART })

  const createCost  = data => costDispatch({ type: COST_ACTIONS.CREATE, report: task.report, data })
  const updateCost  = data => costDispatch({ type: COST_ACTIONS.UPDATE, report: task.report, data: {...data, to_do_item: task.id } })
  const destroyCost = cost => costDispatch({ type: COST_ACTIONS.DESTROY, report: task.report, cost, animation: 'loading' })

  const selfAssignTask   = ()              => dispatch({ type: TASK_ACTIONS.SELF_ASSIGN,  task,   params: { report: true, costs: true }, callbacks: [() => changeView('current')]}, )
  const updateTask       = (data, fieldset)            => dispatch({
    type:      TASK_ACTIONS.UPDATE,
    task,
    data,
    fieldset,
    params:    { report: true, costs: true },
    callbacks: [closeModal]
  })
  const openTask         = ()              => dispatch({ type: TASK_ACTIONS.OPEN,  task,          params: { report: true, costs: true }, animation: 'loading', callbacks: [] })
  const stopTask         = ()              => dispatch({ type: TASK_ACTIONS.STOP, task,           params: { report: true, costs: true }, animation: 'loading', callbacks: [() => setOngoingTask(null)]})
  const answerQuestion   = (answer, reply) => dispatch({ type: TASK_ACTIONS.ANSWER_QUESTION, params: { report: true, costs: true }, answer, reply, task })
  const startTask        = ()              => dispatch({ type:      TASK_ACTIONS.START,  task,         params:    { report: true, costs: true }, animation: 'loading',
    callbacks: [
      (data) => setOngoingTask(data.response),
      () => setStartedAt(Date.now()),
      () => setTimer(0),
      () => setOpened(true),
      () => taskRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' }),
      () => ongoingRef.current = taskRef.current
    ]}
  )

  const doneTask = (data, fieldset) => dispatch({
    type:      TASK_ACTIONS.DONE,
    task,
    animation: 'loading',
    data,
    fieldset,
    params:    { report: true, costs: true },
    callbacks: [
      () => setOpened(false),
      () => (ongoingTask?.id === task.id ? setOngoingTask(null) : null),
      closeModal
    ]}
  )

  const deletePeriod = period                => dispatch({ type: TASK_ACTIONS.DELETE_PERIOD, task, period })
  const updatePeriod = (period, dates, time, notify) => dispatch({ type: TASK_ACTIONS.UPDATE_PERIOD, period, dates, time, notify })

  useEffect(() => {
    if (task.action !== 'stop') return
    const interval = setInterval(() => setTimer(_timer => Date.now() - new Date(task.action_started_at).getTime()), 1000)
    return () => clearInterval(interval)
  }, [task.action])

  useEffect(() => {
    if (!opened) return
    taskRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    fetchApi({
      url:      `/to_do_items/${task.id}/rep_show`,
      callback: data => {
        setTask(data)
        setNotifCount(data.report.notification_count)
      }
    })
  }, [opened])

  const doneTaskModal = useCallback(() => {
    if (task.forms.filter(f => !f.complete).length) return toast(i18n.t('todo.error.must_complete_forms'), { icon: '⚠️', style: { color: 'var(--rep-danger)' }})
    return showModal({
      title:   i18n.t('todo.actions.close'),
      content: <TaskContextProvider
        key        = {task.id}
        serverTask = {task}
      >
        <DoneForm
          report   = {task.report}
          callback = {doneTask}
        />
      </TaskContextProvider>
    })
  }, [task])

  const editCost = (_event, cost) => {
    showModal({
      title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
      content: <CostForm
        cost       = {cost}
        task       = {task}
        type       = {cost.line_type}
        report     = {task.report}
        updateCost = {updateCost}
      />
    })
  }

  // const showFileUploader = useCallback(() =>
  //   showModal({
  //     title:   i18n.t('document.attach_file'),
  //     content: <FileUploader
  //       files           = {task.documents}
  //       objectType      = 'report'
  //       objectId        = {task.report.id}
  //       updateMethods   = {updateMethods}
  //       closeModalAfter = {false}
  //       gridSize        = {2}
  //       multiple
  //     />
  //   }), [task.documents])

  const FileUploadRef = useRef(null)
  const showFileUploader = () => {
    FileUploadRef.current.click()
  }
  const [_documentState, documentDispatch] = useReducer(documentReducer, { fetchApi, updateMethods, closeModal })
  const uploadFile = event => {
    uploadFunction(file =>
      documentDispatch({
        type:       DOCUMENT_ACTIONS.CREATE,
        file,
        objectType: 'report',
        objectId:   task.report.id,
      }), event.currentTarget.files)
  }

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  const actions = useMemo(() => {
    const actionsArray = []

    if (task.permissions.update_task) {
      actionsArray.push({
        icon:       <FontAwesomeIcon icon="edit" />,
        color:      'var(--rep-primary)',
        background: 'var(--rep-primary-light)',
        content:    i18n.t('actions.edit'),
        click:      () => showModal({
          title:   i18n.t('todo.actions.edit_task'),
          content: <TaskForm
            task          = {task}
            report        = {task.report}
            updateMethods = {updateMethods}
            updatePeriod  = {updatePeriod}
            deletePeriod  = {deletePeriod}
          />
        })
      })
      actionsArray.push({ separator: true })
    }

    // Material requests
    if (task.report.permissions.can_create_material_request) {
      actionsArray.push({
        icon:      <FontAwesomeIcon icon="code-pull-request" />,
        color:     'var(--rep-neutral-primary)',
        content:   i18n.t('material_request.material_request'),
        separator: true,
        click:     () => showModal({
          title:   i18n.t('material_request.actions.prepare_request'),
          content: <RequestForm report={task.report} task={task} callback={() => API.fetchTask()}/>,
        })
      })
      actionsArray.push({ separator: true })
    }

    if (current_company.permissions.can_use_machine_stops && !!task.report.machine_stop) {
      if (task.report.machine_stop.permissions.can_ready_machine_stop) actionsArray.push({
        icon:       <FontAwesomeIcon icon="wrench" />,
        color:      'var(--rep-warning)',
        background: 'var(--rep-warning-light)',
        content:    i18n.t('machine_stop.mark_as_ready'),
        click:      machineReady
      })
      if (task.report.machine_stop.permissions.can_end_machine_stop) actionsArray.push({
        icon:       <FontAwesomeIcon icon="plug-circle-check" />,
        color:      'var(--rep-success)',
        background: 'var(--rep-success-light)',
        content:    i18n.t('machine_stop.mark_as_restarted'),
        click:      machineRestarted
      })
      if (task.report.machine_stop.permissions.can_restop_machine_stop) actionsArray.push({
        icon:       <FontAwesomeIcon icon="wrench" />,
        color:      'var(--rep-danger)',
        background: 'var(--rep-danger-light)',
        content:    i18n.t('machine_stop.mark_as_stopped'),
        click:      machineRestop
      })
      actionsArray.push({ separator: true })
    }

    actionsArray.push({
      icon:       <FontAwesomeIcon icon="arrow-up-right-from-square" />,
      color:      'var(--rep-primary)',
      background: 'var(--rep-primary-light)',
      content:    `${i18n.t('report.actions.view_report')} ${task.report.title}`,
      // click:      () => window.open(`/reports/${task.report.id}`, '_blank', 'noopener,noreferrer')
      click:      () => openDetails(task.report, 'reports')
    })

    if (task.report.unit) {
      actionsArray.push({
        icon:       <FontAwesomeIcon icon="arrow-up-right-from-square" />,
        color:      'var(--rep-primary)',
        background: 'var(--rep-primary-light)',
        content:    `${i18n.t('unit.actions.see_unit')} ${task.report.unit.name}`,
        // click:      () => { window.open(`/units/${task.report.unit.id}`, '_blank', 'noopener,noreferrer') }
        click:      () => openDetails(task.report.unit, 'units')
      })
    }

    if (task.report.amenity) {
      actionsArray.push({
        icon:       <FontAwesomeIcon icon="arrow-up-right-from-square" />,
        color:      'var(--rep-primary)',
        background: 'var(--rep-primary-light)',
        content:    `${i18n.t('amenity.actions.see')} ${task.report.amenity.name}`,
        // click:      () => { window.open(`/amenities/${task.report.amenity.id}`, '_blank', 'noopener,noreferrer') }
        click:      () => setInfoWindowProps({ ...task.report.amenity, resourceType: 'amenities' })
      })
    }

    if (current_company.permissions.can_see_costs && current_company.permissions.access_stock && task.permissions.can_create_material_costs) {
      actionsArray.push({
        icon:    <FontAwesomeIcon icon="qrcode" />,
        color:   'var(--rep-primary)',
        content: 'QR',
        click:   () => showModal({
          title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
          content: <CostForm
            report     = {task.report}
            task       = {task}
            type       = {COST.TYPE.INVENTORY}
            createCost = {createCost}
            revealQR   = {true}
          />
        })
      })
    }

    return actionsArray
  }, [task, task.report, task.report.machine_stop])

  const costActions = useMemo(() => {
    const actionsArray = []
    if (current_company.permissions.can_see_costs) {
      if (task.permissions.can_create_hour_costs) {
        actionsArray.push({
          icon:    <FontAwesomeIcon icon="user-clock" />,
          content: i18n.t('offer_line.actions.add_cost_hours'),
          click:   () => showModal({
            title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
            content: <CostForm
              report     = {task.report}
              task       = {task}
              type       = {COST.TYPE.HOURS}
              createCost = {createCost}
            />
          })
        })
      }
      if (task.permissions.can_create_transport_costs) {
        actionsArray.push({
          icon:    <FontAwesomeIcon icon="truck-moving" />,
          content: i18n.t('offer_line.actions.add_cost_transport'),
          click:   () => showModal({
            title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
            content: <CostForm
              report     = {task.report}
              task       = {task}
              type       = {COST.TYPE.TRANSPORT}
              createCost = {createCost}
            />
          })
        })
      }
      if (task.permissions.can_create_invoice_costs) {
        actionsArray.push({
          icon:    <FontAwesomeIcon icon="file-invoice-dollar" />,
          content: i18n.t('offer_line.actions.add_cost_external_invoice'),
          click:   () => showModal({
            title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
            content: <CostForm
              report     = {task.report}
              task       = {task}
              type       = {COST.TYPE.EXTERNAL_INVOICE}
              createCost = {createCost}
            />
          })
        })
      }
      if (current_company.permissions.access_stock && task.permissions.can_create_material_costs) {
        actionsArray.push({
          icon:    <FontAwesomeIcon icon="cubes" />,
          content: i18n.t('offer_line.actions.add_cost_material'),
          click:   () => showModal({
            title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
            content: <CostForm
              report     = {task.report}
              task       = {task}
              type       = {COST.TYPE.INVENTORY}
              createCost = {createCost}
            />
          })
        })
      }
    }
    return actionsArray
  }, [task.status, task.permissions, task.report])

  const doneApproveAction = useMemo(() => {
    const result = []
    if (task.permissions.done_task && (task.status !== TASK.STATUS.NEW)) result.push(
      <Button
        key   = 'action-done'
        icon  = {task.forms.filter(f => !f.complete).length ? <FontAwesomeIcon icon="hourglass" /> : <FontAwesomeIcon icon='check' />}
        color = {task.forms.filter(f => !f.complete).length ? 'var(--rep-neutral-primary)'         : 'var(--rep-success)'      }
        hover = {task.forms.filter(f => !f.complete).length ? 'var(--rep-neutral-primary-light)'   : 'var(--rep-success-light)'}
        click = {doneTaskModal}
      >
        {i18n.t('todo.actions.close_task')}
      </Button>
    )
    if (task.permissions.open_task) result.push(
      <Button
        key   = 'action-open'
        icon  = {<FontAwesomeIcon icon="lock-open" />}
        color = 'var(--rep-warning)'
        hover = 'var(--rep-warning-light)'
        click = {openTask}
      >
        {i18n.t('todo.actions.reopen_task')}
      </Button>
    )
    return result
  }, [task])


  const showQuestionsForm = useCallback(reply =>
    showModal({
      title:   reply.name,
      content: reply.questions.map(question =>
        <FormQuestion
          key            = {question.id}
          question       = {question}
          form           = {reply}
          answerQuestion = {answerQuestion}
        />
      )
    }), [task])

  const stopTag = useMemo(() => {
    if (!current_company.permissions.can_use_machine_stops) return
    switch(task.report?.machine_stop?.status) {
      case MACHINE_STOP.STATUS.STOPPED:
        return  <Tag
          tooltipContent = {i18n.t('machine_stop.status.stopped')}
          icon           = {<FontAwesomeIcon icon="explosion" />}
          color          = 'var(--rep-danger)'
          hover          =  'var(--rep-danger-light)'
        >
          {task.report.amenity.name}
        </Tag>
      case MACHINE_STOP.STATUS.READY:
        return  <Tag
          tooltipContent = {i18n.t('machine_stop.status.ready')}
          icon           = {<FontAwesomeIcon icon="wrench" />}
          color          = 'var(--rep-warning)'
          hover          =  'var(--rep-warning-light)'
        >
          {task.report.amenity.name}
        </Tag>
      case MACHINE_STOP.STATUS.RESTARTED:
        return  <Tag
          tooltipContent = {i18n.t('machine_stop.status.restarted')}
          icon           = {<FontAwesomeIcon icon="plug-circle-check" />}
          color          = 'var(--rep-success)'
          hover          = 'var(--rep-success-light)'
        >
          {task.report.amenity.name}
        </Tag>
      default: return null
    }
  }, [task.report.machine_stop])

  const statusIcon = ({
    [TASK.STATUS.NEW]:      <FontAwesomeIcon fixedWidth icon={['far', 'paper-plane']} />,
    [TASK.STATUS.ASSIGNED]: <FontAwesomeIcon fixedWidth icon={['far', 'user'       ]} />,
    [TASK.STATUS.ONGOING]:  <FontAwesomeIcon fixedWidth icon={['far', 'circle'     ]} fade />,
    [TASK.STATUS.DONE]:     <FontAwesomeIcon fixedWidth icon={['far', 'eye'        ]} />,
    [TASK.STATUS.CLOSED]:   <FontAwesomeIcon fixedWidth icon='check' />
  })[task.status] ?? <FontAwesomeIcon fixedWidth icon={['far', 'paper-plane']} />

  const costIcon = (costType) => ({
    [COST.TYPE.HOURS]:            <FontAwesomeIcon icon="user-clock" />,
    [COST.TYPE.TRANSPORT]:        <FontAwesomeIcon icon="truck-moving" />,
    [COST.TYPE.INVENTORY]:        <FontAwesomeIcon icon="cubes" />,
    [COST.TYPE.EXTERNAL_INVOICE]: <FontAwesomeIcon icon="file-invoice-dollar" />,
  })[costType] ?? <FontAwesomeIcon icon="dollar-sign" />

  const costStatus = (status) => ({
    [COST.STATUS.ONGOING]:    <WithTooltip content={i18n.t(`offer_line.status.${status}`)}><FontAwesomeIcon fixedWidth icon="fa-regular fa-circle" beatFade /></WithTooltip>,
    [COST.STATUS.APPROVED]:   <WithTooltip content={i18n.t(`offer_line.status.${status}`)}><FontAwesomeIcon icon="check"          color='var(--rep-success)' /></WithTooltip>,
    [COST.STATUS.REJECTED]:   <WithTooltip content={i18n.t(`offer_line.status.${status}`)}><FontAwesomeIcon icon="times"          color='var(--rep-danger)' /></WithTooltip>,
    [COST.STATUS.TO_APPROVE]: <WithTooltip content={i18n.t(`offer_line.status.${status}`)}><FontAwesomeIcon icon={['far', 'clock']} color='var(--rep-neutral)' /></WithTooltip>,
  })[status] ?? <FontAwesomeIcon icon="dollar-sign" />

  return (
    <div
      ref   = {taskRef}
      style = {{
        scrollMarginTop:    '80px',
        scrollMarginBottom: '120px',
        position:           'relative'
      }}
    >
      <Card
        togglable    = {task.status !== TASK.STATUS.NEW}
        opened       = {opened}
        setOpened    = {setOpened}
        stickyHeader = {opened}
        animation    = {animation}
        border       = 'var(--rep-neutral-primary-light)'
        headerTop    = {
          <Style.TaskHeader onClick={() => setOpened(!opened)}>
            <Style.TaskStatus status={task.status}>
              {statusIcon}
              {i18n.t(`todo.status.${task.status}`)}
            </Style.TaskStatus>

            <Style.TaskTopActions>
              {!!task.material_requests.length
                && task.material_requests.map(request =>
                  <Tag
                    key            = {request.id}
                    icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
                    color          = {request.status === 'requested' ? 'var(--rep-warning)' : request.status === 'draft' ? 'var(--rep-neutral)' : 'var(--rep-success)'}
                    background     = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                    hover          = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                    tooltipContent = {`[${request.status === 'draft' ? i18n.t('material_request.status.draft') : request.number}] Materials requested by ${request.requester.name}`}
                    click          = {() => window.open(`/material_requests/${request.id}`, '_blank', 'noopener,noreferrer')}
                  />
                )}
              {stopTag}
              {!!task.report.type &&
                <Tag
                  icon           = {<FontAwesomeIcon icon={task.report.type.logo} />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {task.report.type.name}
                />
              }
              {!!task.report.priority &&
                <Tag
                  icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {i18n.t('report.priority')}
                >
                  {task.report.priority.name}
                </Tag>
              }
              {task.status === TASK.STATUS.NEW && !!task.planned_end &&
                <Tag
                  icon           = {<FontAwesomeIcon icon={['far', 'calendar-xmark']} />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {i18n.t('todo.stop')}
                >
                  {new Date(task.planned_end).toLocaleDateString(i18n.locale)}
                </Tag>
              }
              {task.status !== TASK.STATUS.NEW && !!task.end_ptt_date &&
                <Tag
                  icon           = {<FontAwesomeIcon icon={['far', 'calendar-xmark']} />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {i18n.t('todo.stop')}
                >
                  {new Date(task.end_ptt_date).toLocaleDateString(i18n.locale)}
                </Tag>
              }
              {current_company.permissions.see_estimated_time && !!task.duration &&
                <Tag
                  icon           = {<FontAwesomeIcon icon="hourglass" />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {i18n.t('todo.expected_duration')}
                >
                  {useHumanMs(task.duration)}
                </Tag>
              }
              {task.report.urgent &&
                <Tag
                  background     = 'var(--rep-danger)'
                  border         = 'var(--rep-danger)'
                  color          = 'white'
                  icon           = {<FontAwesomeIcon icon="land-mine-on" size="sm"/>}
                  tooltipContent = {i18n.t('report.urgent')}
                >{i18n.t('report.urgent')}</Tag>
              }
              <Style.Avatars>
                {!!task.done_by &&
                  <Avatar
                    firstName  = {task.done_by.first_name}
                    lastName   = {task.done_by.last_name}
                    background = 'transparent'
                    border     = 'var(--rep-neutral-primary-light)'
                    color      = 'var(--rep-neutral-primary)'
                    title      = {i18n.t('todo.done_on', { date: new Date(task.done_date).toLocaleDateString(i18n.locale) })}
                  />
                }
                {!!task.approved_by &&
                  <Avatar
                    firstName  = {task.approved_by.first_name}
                    lastName   = {task.approved_by.last_name}
                    background = 'transparent'
                    border     = 'var(--rep-success-light)'
                    color      = 'var(--rep-success)'
                    title      = {i18n.t('todo.approved_on', { date: new Date(task.approval_date).toLocaleDateString(i18n.locale) })}
                  />
                }
              </Style.Avatars>
            </Style.TaskTopActions>
            {!opened && notifCount > 0 &&
              <Style.UnseenMsgCount>{notifCount}</Style.UnseenMsgCount>
            }
          </Style.TaskHeader>
        }
        header       = {
          <>
            <Style.TaskTitle>{task.title}</Style.TaskTitle>
            <Style.TaskText>{task.description}</Style.TaskText>
          </>
        }
        bodyExpanded = {
          <>
            {task.status === TASK.STATUS.DONE && !!task.comment &&
              <Callout
                title      = {i18n.t('todo.closing_comment_from', { user: task.done_by.name })}
                icon       = {<FontAwesomeIcon icon="comment-dots" />}
                color      = 'var(--rep-neutral-primary)'
                background = 'none'
                border     = 'var(--rep-primary-light)'
                marginY    = 'S'
              >
                {task.comment}
              </Callout>
            }
            <div style={{ display: 'flex', gap: '8px', margin: '8px 0', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              <Style.TaskActions>
                {doneApproveAction}
              </Style.TaskActions>
              <Dropdown
                withArrow
                icon       = {<FontAwesomeIcon icon="bezier-curve" />}
                color      = 'var(--rep-neutral-primary)'
                background = 'var(--rep-neutral-light)'
                hover      = 'var(--rep-neutral-light)'
                options    = {actions}
              >
                {i18n.t('actions.other_actions')}
              </Dropdown>
            </div>

            {current_company.permissions.can_see_forms && !!task.forms.length &&
              <Style.TaskForms>
                {task.forms.map(reply =>
                  <Button
                    key        = {reply.id}
                    click      = {() => showQuestionsForm(reply)}
                    icon       = {reply.complete ? <FontAwesomeIcon icon="clipboard-check" /> : <FontAwesomeIcon icon="clipboard" />}
                    color      = {reply.complete ? 'var(--rep-success)' : 'var(--rep-warning)'}
                    background = {reply.complete ? 'none' : 'var(--rep-warning-light)'}
                    hover      = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-warning-light)'}
                    border     = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-warning-light)'}
                  >
                    {reply.name}
                  </Button>
                )}
              </Style.TaskForms>
            }

            {[TASK.STATUS.DONE, TASK.STATUS.CLOSED].includes(task.status) && !!task.documents.length &&
              <Style.FileListStyles>
                {task.documents.map(doc =>
                  <Thumbnail
                    key  = {doc.id}
                    file = {doc}
                  />
                )}
              </Style.FileListStyles>
            }

            <Accordion
              open        = {false}
              togglable   = {true}
              colorHeader = 'var(--rep-neutral-primary)'
              icon        = {<FontAwesomeIcon icon="users" />}
              header      = {<div>{i18n.t('todo.work_periods')} <sup>{task.work_periods.length}</sup></div>}
            >
              {task.work_periods.map(period =>
                <WorkPeriod
                  key      = {period.id}
                  task     = {task}
                  period   = {period}
                  // callback = {updateTask}
                  // editable = {false}
                />)
              }
            </Accordion>



            {!!current_company.permissions.can_see_costs &&
              <Accordion
                open        = {false}
                togglable   = {!!task.costs.length}
                colorHeader = 'var(--rep-neutral-primary)'
                icon        = {<FontAwesomeIcon icon="chart-line" />}
                header      = {<div>{i18n.t('offer_line.activities')}<sup>{task.costs.length}</sup></div>}
                action={
                  <Dropdown
                    withArrow
                    icon       = {<FontAwesomeIcon icon="plus" />}
                    color      = 'var(--rep-neutral)'
                    hover      = 'var(--rep-neutral-light)'
                    options    = {costActions}
                  >
                    {i18n.t('actions.add')}
                  </Dropdown>
                }
              >
                {task.costs.map((cost, key) =>
                  <Style.CostLine key={key} status={cost.status} withPrices={current_company.permissions.tech_can_see_prices}>
                    <div>{costStatus(cost.status)}</div>
                    <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                      <WithTooltip content={cost.line_type}>
                        {costIcon(cost.line_type)}
                      </WithTooltip>
                      {cost.name}
                    </div>
                    <div>
                      {cost.line_type === COST.TYPE.HOURS
                        ? useHumanMs(cost.quantity)
                        : cost.quantity
                      }
                    </div>
                    {current_company.permissions.tech_can_see_prices &&
                      <div>
                        {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(cost.total)}
                      </div>
                    }
                    {!!cost.for_user &&
                      <Avatar firstName={cost.for_user.first_name} lastName={cost.for_user.last_name}/>
                    }
                    <div style={{justifySelf: 'flex-end'}}>
                      <Dropdown
                        icon        = {<FontAwesomeIcon icon="ellipsis-h" />}
                        background  = 'var(--rep-primary-light)'
                        hover       = 'var(--rep-primary-light)'
                        color       = 'var(--rep-primary)'
                        options     = {[
                          (cost.permissions.update_cost
                            ? {
                              icon:       <FontAwesomeIcon icon="pen" />,
                              color:      'var(--rep-primary)',
                              background: 'var(--rep-primary-light)',
                              content:    'Edit',
                              click:      (event) => editCost(event, cost)
                            }
                            : null
                          ),
                          (cost.permissions.destroy_cost
                            ? {
                              icon:       <FontAwesomeIcon icon="trash" />,
                              color:      'var(--rep-danger)',
                              background: 'var(--rep-danger-light)',
                              content:    'Delete',
                              click:      () => destroyCost(cost)
                            }
                            : null
                          ),
                        ].filter(v => v)}
                      />
                    </div>
                  </Style.CostLine>
                )}
              </Accordion>
            }

            <Accordion
              open        = {false}
              togglable   = {!!task.report.documents.length}
              colorHeader = 'var(--rep-neutral-primary)'
              icon        = {<FontAwesomeIcon icon="photo-film" />}
              header      = {<div>{i18n.t('shared.documents')} <sup>{task.report.documents.length}</sup></div>}
              action      = {
                task.report.permissions.can_create_document &&
                  <Button
                    icon  = {<FontAwesomeIcon icon="camera" />}
                    color = 'var(--rep-neutral)'
                    hover = 'var(--rep-neutral-light)'
                    click = {showFileUploader}
                  >
                    {i18n.t('actions.add')}
                  </Button>
              }
            >
              <input type="file" multiple style={{display: 'none' }} ref={FileUploadRef} onChange={uploadFile} />
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', gap: '8px'}}>
                {task.report.documents.map(document =>
                  <Thumbnail
                    key  = {document.id}
                    file = {document}
                  />)}
              </div>
            </Accordion>

            <Chat
              report        = {task.report}
              updateMethods = {updateMethods}
            />
          </>
        }
      >
        {task.status === TASK.STATUS.NEW && current_company.permissions.tech_can_create_tasks &&
          <Button
            icon  = {<FontAwesomeIcon icon="handshake" />}
            click = {selfAssignTask}
            color = 'var(--rep-primary)'
            hover = 'var(--rep-primary-light)'
          >
            {i18n.t('todo.actions.take_task')}
          </Button>
        }
        {!!task.action &&
          <div style={{ flexGrow: 1, alignSelf: 'stretch', margin: '8px 0' }}>
            {task.action === TASK.ACTIONS.START && task.permissions.start_task &&
              <Style.StartStop
                onClick = {startTask}
                status  = 'start'
              >
                <FontAwesomeIcon icon="play" /><span>start</span>
              </Style.StartStop>
            }
            {task.action === TASK.ACTIONS.STOP && task.permissions.stop_task &&
              <>
                <Style.OngoingTimer>
                  <FontAwesomeIcon icon="stopwatch" />{convertMsToMinutesSeconds(timer)}
                </Style.OngoingTimer>
                <Style.StartStop onClick={stopTask} status='stop'>
                  <FontAwesomeIcon icon="stop" /><span>stop</span>
                </Style.StartStop>
              </>
            }
          </div>
        }
        <div style={{display: 'flex', gap: '8px', overflowX: 'scroll', scrollbarWidth: 'none', marginTop: '8px', marginBottom: '8px'}}>
          {task.report.unit &&
            <Tag
              icon           = {<FontAwesomeIcon icon="location-dot" />}
              color          = 'var(--rep-neutral)'
              background     = 'var(--rep-neutral-light)'
              tooltipContent = {i18n.t('unit.unit')}
              click          = {() => openDetails(task.report.unit, 'units')}
            >
              {task.report.unit.name}
            </Tag>
          }

          {task.report.amenity &&
            <Tag
              icon           = {<FontAwesomeIcon icon="gear" />}
              color          = 'var(--rep-neutral)'
              background     = 'var(--rep-neutral-light)'
              tooltipContent = {i18n.t('amenity.amenity')}
              click          = {() => openDetails(task.report.amenity, 'amenities')}
            >
              {task.report.amenity.name}
            </Tag>
          }

          <Tag
            icon           = {<FontAwesomeIcon icon="ticket" />}
            color          = 'var(--rep-neutral)'
            background     = 'var(--rep-neutral-light)'
            tooltipContent = {i18n.t('report.report')}
            click          = {() => openDetails(task.report, 'reports')}
          >
            {task.report.title}
          </Tag>
        </div>
        {!!task.report.type &&
          <Tag
            icon           = {<FontAwesomeIcon icon={task.report.type.logo} />}
            color          = 'var(--rep-neutral-primary)'
            background     = 'var(--rep-neutral-light)'
            hover          = 'var(--rep-neutral-primary-light)'
            tooltipContent = {task.report.type.name}
          >
            {task.report.type.name}
          </Tag>
        }
      </Card>
    </div>
  )
}

export default TaskCard
