import React, { useEffect, useMemo, useReducer, useState }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion       from '@components/Accordion'
import AddEquipmentToContract from '@components/Contract/Link'
import AmenityForm     from '@components/Amenity/Form'
import Avatar          from '@components/Avatar'
import AvatarList      from '@components/AvatarList'
import Button          from '@components/Button'
import ContractCard    from '@components/Contract'
import ContractForm    from '@components/Contract/Form'
import Dropdown        from '@components/Dropdown'
import Expander        from '@components/Expander'
import FieldTag        from '@components/Form/FieldTag'
import { FileGrid }    from '@components/Form/FileUploader/Thumbnail/style'
import FileUploader    from '@components/Form/FileUploader'
import Line            from '@components/Charts/Line'
import MachineStopTag  from '@components/MachineStop/MachineStopTag'
import MaintenanceCard from '@components/Maintenance/MaintenanceCard'
import Measure         from '@components/Measure'
import MeasureForm     from '@components/Measure/Form'
import MultiselectItem from '@components/Form/Select/MultiselectItem'
// import QRCode          from '@components/QRCode/QRCode'
import ReportCard      from '@components/Report/ReportCard'
import ReportForm      from '@components/Report/Form'
import SelectUserForm  from '@components/User/SelectUserForm'
import StartUsageForm  from '@components/EquipmentUsage/StartUsageForm'
import StopUsageForm   from '@components/EquipmentUsage/StopUsageForm'
import Tag             from '@components/Tag'
import Thumbnail       from '@components/Form/FileUploader/Thumbnail'
import Tree            from '@components/Tree'
import WithTooltip     from '@components/WithTooltip'

import AddEquipmentToPlan from '@pages/Maintenances/Form/AddEquipmentToPlan'
import PlanForm           from '@pages/Maintenances/Form/PlanForm'

import { useAmenityContextState } from '@context/AmenityContext'
import { useGlobalContextState }  from '@context/GlobalContext'

import * as InfoStyle from '@components/InfoWindow/style'

import { AMENITY_ACTIONS, amenityReducer }    from '@reducers/amenityReducer'
import { CONTRACT_ACTIONS, contractReducer }  from '@reducers/contractReducer'
import { MEASURE_ACTIONS, measurmentReducer } from '@reducers/measureReducer'
import { MaintenancesContextProvider } from '@context/MaintenancesContext'


const InfoAmenity = ({
  readOnly,
  fromModal,
  sidebarWidth,
  infoHistory,
  historyBack,
  closeInfoWindow
}) => {

  const { fetchApi, i18n, current_user, showModal, isDesktop, setInfoWindowProps, closeModal, current_company, showAlert, closeAlert, CONSTANTS } = useGlobalContextState()
  const { amenity, setAmenity, updateMethods } = useAmenityContextState()

  const [expanded, setExpanded] = useState(false)
  // const [showQR,   setShowQR]   = useState(false)
  const [loading,  setLoading]  = useState(true)

  const [ongoingReports,    setOngoingReports]    = useState([])
  const [ongoingPagination, setOngoingPagination] = useState({})
  const [ongoingLoading,    setOngoingLoading]    = useState(false)

  const [_state, dispatch]                 = useReducer(amenityReducer, { fetchApi, closeModal, updateMethods })
  const [_contractState, contractDispatch] = useReducer(contractReducer, { fetchApi, closeModal, updateMethods })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    urlParams.set('info_type', 'amenities')
    urlParams.set('info_id',    amenity.id)

    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
  }, [amenity])

  const params = useMemo(() => ({
    tickets:              true,
    unit:                 true,
    machine_stop:         true,
    machine_stop_reports: true,
    contracts:            true,
    documents:            true,
    measurments:          true,
    maintenances:         true,
    stop_history:         true,
    ancestors:            true,
    children:             true,
    suggested_user:       true,
    path:                 true,
    custom_fields:        true,
    permissions:          true,

    employees:       true,
    responsibles:    true,
    invoice_contact: true,
    costs:           true,
  }), [])

  const fetchAmenity  = ()   => dispatch({ type: AMENITY_ACTIONS.FETCH,  amenity, params, callbacks: [() => setLoading(false)] })
  const addToPlan     = (data, fieldset) => dispatch({ type: AMENITY_ACTIONS.ADD_TO_PLAN, amenity, data, fieldset, callbacks: [fetchAmenity, closeModal]})
  const addUsers      = (data, fieldset) => dispatch({ type: AMENITY_ACTIONS.ADD_USERS, data, amenity, params, fieldset, callbacks: [fetchAmenity, closeModal] })

  const startUsage = (data, fieldset)            => dispatch({ type: AMENITY_ACTIONS.START_USAGE, amenity, data, fieldset, callbacks: [closeModal] })
  const takeUsage  = (data, fieldset)            => dispatch({ type: AMENITY_ACTIONS.TAKE_USAGE, amenity, usage_id: amenity.usage.id, data, fieldset, callbacks: [closeModal] })
  const stopUsage  = ({ usage, data }, fieldset) => dispatch({ type: AMENITY_ACTIONS.STOP_USAGE, amenity, usage_id: usage.id, data, fieldset, callbacks: [closeModal] })

  const archive   = () => dispatch({ type: AMENITY_ACTIONS.ARCHIVE, amenity, params, callbacks: [fetchAmenity, closeAlert] })
  const unarchive = () => dispatch({ type: AMENITY_ACTIONS.UNARCHIVE, amenity, params, callbacks: [fetchAmenity] })

  const createContract  = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.CREATE, amenity, fieldset, data: { contract: data }, callbacks: [fetchAmenity, closeModal]})
  const updateContract  = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.UPDATE, amenity, fieldset, data, callbacks: [fetchAmenity, closeModal]})
  const addToContract   = (data, fieldset) => contractDispatch({ type: CONTRACT_ACTIONS.LINK, data: { id: data.contract, amenity_id: amenity.id }, fieldset, callbacks: [fetchAmenity, closeModal]})
  const unlinkContract  = contract => contractDispatch({ type: CONTRACT_ACTIONS.UNLINK, amenity, data: { amenity_id: amenity.id, id: contract.id }, callbacks: [fetchAmenity]})

  const fetchOngoingReports = page => {
    setOngoingLoading(true)
    dispatch({
      type:       AMENITY_ACTIONS.FETCH_ONGOING_REPORTS,
      amenity,
      page,
      pagination: ongoingPagination,
      callbacks:  [
        data => setOngoingReports(ongoing => [...ongoing, ...data.reports]),
        data => setOngoingPagination(data.pagination),
        () => setOngoingLoading(false)
      ]})
  }

  const resetOngoingReports = () => {
    setOngoingReports([])
    setOngoingPagination({})
  }

  useEffect(() => {
    setLoading(true)
    fetchAmenity()
    setOngoingReports([])
    setOngoingPagination({})
  }, [amenity.id])

  // Cost chart setup
  const categories = useMemo(() => {
    if (!amenity?.costs) return
    return Object.keys(amenity.costs.total_details).filter(e => e)
      .map(d => new Date(d).toLocaleDateString(i18n.locale))
  }, [amenity])

  const series = useMemo(() => {
    if (!amenity?.costs) return
    return [
      {
        color: 'var(--rep-success)',
        name:  'Total costs',
        data:  Object.values(amenity.costs.total_details).filter(e => e)
      }
    ]
  }, [amenity])

  const actions = useMemo(() => {
    const resultActions = []

    if (readOnly || !amenity?.permissions) return resultActions

    if (amenity.permissions.can_update_amenity) resultActions.push({
      icon:    <FontAwesomeIcon icon="pen" />,
      color:   'var(--rep-primary)',
      content: i18n.t('actions.edit'),
      click:   () => showModal({
        title:   i18n.t('amenity.actions.edit_equip'),
        content: <AmenityForm amenity={amenity} updateMethods={updateMethods} />,
      })
    })

    if (amenity.permissions.can_archive_amenity) resultActions.push({
      icon:    <FontAwesomeIcon icon="folder" />,
      color:   'var(--rep-warning)',
      content: i18n.t('actions.archive'),
      click:   () => {
        showAlert(
          i18n.t('amenity.actions.archive_alert'),
          [{
            icon:       <FontAwesomeIcon icon="folder" />,
            click:      archive,
            background: 'var(--rep-warning)',
            color:      'white',
            content:    i18n.t('amenity.actions.archive')
          }]
        )
      }
    })

    if (amenity.permissions.can_unarchive_amenity) resultActions.push({
      icon:    <FontAwesomeIcon icon="folder-open" />,
      color:   'var(--rep-warning)',
      content: i18n.t('actions.unarchive'),
      click:   unarchive
    })

    // resultActions.push({
    //   icon:    <FontAwesomeIcon icon="qrcode" />,
    //   color:   'var(--rep-accent)',
    //   content: i18n.t('shared.qr_code'),
    //   click:   () => setShowQR(showQR => !showQR)
    // })

    return resultActions
  }, [amenity])

  if (!amenity || !amenity.permissions)return(<InfoStyle.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></InfoStyle.Loader>)
  if (loading) return(<InfoStyle.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></InfoStyle.Loader>)

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({...object, resourceType: type, fromModal})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      {/* {amenity.permissions.can_use_machine_stops &&
        <Columns
          categories = {amenity.stop_history[0]}
          series = {amenity.stop_history[1]}
          // title= 'Arrêts les 30 derniers jours'
          stacked
        />
      } */}
      <InfoStyle.InfoHeader width={sidebarWidth} isDesktop={isDesktop}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {infoHistory.length > 1
              ? <InfoStyle.BackIcon onClick={historyBack}>
                <FontAwesomeIcon icon="caret-left" />
              </InfoStyle.BackIcon>
              : <div></div>
            }

            <Tag
              icon       = {<FontAwesomeIcon icon="circle-info" />}
              background = 'var(--rep-neutral-light)'
              color      = 'var(--rep-neutral-primary)'
            >
              {i18n.t('amenity.amenity')}
            </Tag>
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <InfoStyle.Header archived={amenity.archived} danger={amenity?.machine_stop?.status === CONSTANTS.MACHINE_STOP.STATUS.STOPPED}>
              {/* <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '8px' }}> */}
              {amenity.archived &&
                  <Tag
                    icon       = {<FontAwesomeIcon icon="folder" />}
                    background = "var(--rep-warning-light)"
                    color      = "var(--rep-warning)"
                  >
                    {i18n.t('shared.archived')}
                  </Tag>
              }

              {/* </div> */}
            </InfoStyle.Header>

            {!readOnly && !amenity.archived && amenity.permissions.can_create_report &&
              <Button
                icon   = {<FontAwesomeIcon icon="plus" />}
                // border = 'var(--rep-primary)'
                color  = 'var(--rep-primary)'
                hover  = 'var(--rep-primary-light)'
                alingSelf='center'
                click  = {() => showModal({
                  title:   i18n.t('report.actions.open_ticket'),
                  content: <ReportForm updateMethods={{}} report={{ unit: amenity.unit, amenity: amenity }} />
                })}
              >
                {i18n.t('report.report')}
              </Button>
            }
            <Button
              icon   = {<FontAwesomeIcon icon="arrow-up-right-from-square" />}
              // border = 'var(--rep-warning)'
              color  = 'var(--rep-warning)'
              hover  = 'var(--rep-warning-light)'
              click  = {() => window.open(`/amenities/${amenity.id}`, '_blank')}
              align  = 'center'
            >
              {i18n.t('actions.see')}
            </Button>

            <InfoStyle.CloseIcon onClick={closeInfoWindow}>
              <FontAwesomeIcon icon="times" />
            </InfoStyle.CloseIcon>

          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <InfoStyle.Header archived={amenity.archived} danger={amenity?.machine_stop?.status === CONSTANTS.MACHINE_STOP.STATUS.STOPPED}>
            <InfoStyle.TitleActionsContainer>
              {amenity.name}
            </InfoStyle.TitleActionsContainer>
          </InfoStyle.Header>
          {!!actions.length &&
            <Dropdown
              options = {actions}
              icon    = {<FontAwesomeIcon icon="bars" />}
              hover   = 'var(--rep-primary-light)'
            >
              {i18n.t('actions.other_actions')}
            </Dropdown>
          }
        </div>
      </InfoStyle.InfoHeader>

      {/* <hr /> */}

      {/* {showQR &&
        <QRCode
          name    = {amenity.path_string}
          path    = {amenity.qrcode_path}
          closeQr = {() => setShowQR(false)}
        />
      } */}

      <Expander expanded={expanded} setExpanded={setExpanded}>
        <div style={{ margin: '8px 0'}}>
          {amenity.description}
        </div>

        {/* {amenity?.permissions?.can_use_machine_stops &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            Status
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <MachineStopTag
              machine_stop  = {amenity.machine_stop}
              equipment     = {amenity}
              updateMethods = {updateMethods}
              readOnly      = {readOnly}
            />
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        } */}

        {amenity.type &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.amenity_type_long')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <Tag
              icon           = {<FontAwesomeIcon icon="icons" />}
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
            >
              {amenity.type.name}
            </Tag>
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        }

        {(amenity.brand || amenity.model) &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {`${i18n.t('amenity.brand')} — ${i18n.t('amenity.model')}`}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {amenity.brand} — {amenity.model}
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        }

        {amenity.full_address &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('shared.address')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            <Button
              color  = 'var(--rep-primary)'
              hover  = 'var(--rep-primary-light)'
              click  = {() => window.open(`https://www.google.com/maps/place/${amenity.full_address}`, '_blank')}
            >
              {amenity.full_address}
            </Button>
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        }

        {amenity.unit &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('unit.unit')}
          </InfoStyle.InfoLineTitle>
          <Tag
            click          = {() => openDetails(amenity.unit, 'units' ) }
            icon           = {<FontAwesomeIcon icon="map-pin" />}
            hover          = 'var(--rep-primary-light)'
            color          = 'var(--rep-primary)'
            tooltipContent = {amenity.unit.name}
          >
            {amenity.unit.name}
          </Tag>
        </InfoStyle.InfoLine>
        }

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('unit.managers')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {amenity.responsibles?.length
              ? <AvatarList
                users      = {amenity.responsibles}
                background = 'var(--rep-warning-light)'
                color      = 'var(--rep-warning)'
                border     = 'white'
                title      = {i18n.t('amenity.responsible')}
              />
              : <div></div>
            }
            {!readOnly && amenity.permissions.can_change_responsible &&
            <Tag
              icon           = {<FontAwesomeIcon icon="user-pen" />}
              tooltipContent = {i18n.t('shared.actions.change_responsible')}
              hover          = 'var(--rep-primary-light)'
              color          = 'var(--rep-primary)'
              click          = {() => showModal({
                title:   i18n.t('shared.actions.change_responsible'),
                content: <SelectUserForm
                  equipment     = {amenity}
                  type          = 'responsible'
                  callback      = {addUsers}
                  filters       = {['managers']}
                  defaultFilter = {'managers'}
                  multiselect
                />
              })}
            />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('unit.default_technician')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {amenity.technician
              ? <Avatar
                firstName  = {amenity.technician.first_name}
                lastName   = {amenity.technician.last_name}
                background = 'var(--rep-primary-light)'
                color      = 'var(--rep-primary)'
                border     = 'white'
                title      = {i18n.t('unit.default_technician')}
              />
              : <div></div>
            }
            {!readOnly && amenity.permissions.can_change_default_tech &&
              <Tag
                icon           = {<FontAwesomeIcon icon="user-pen" />}
                tooltipContent = {i18n.t('shared.actions.change_default')}
                hover          = 'var(--rep-primary-light)'
                color          = 'var(--rep-primary)'
                click          = {() => showModal({
                  title:   i18n.t('amenity.actions.change_default'),
                  content: <SelectUserForm
                    equipment     = {amenity}
                    type          = 'default_tech'
                    callback      = {addUsers}
                    defaultFilter = {'technicians'}
                  />
                })}
              />
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>

        {amenity.permissions.can_use_equipment_usages &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('equipment_usage.used_by')}
          </InfoStyle.InfoLineTitle>
          <InfoStyle.InfoLineContent>
            {amenity.usage?.id
              ? <>
                <Avatar
                  firstName  = {amenity.usage.user.first_name}
                  lastName   = {amenity.usage.user.last_name}
                  background = 'var(--rep-success-light)'
                  color      = 'var(--rep-success)'
                  border     = 'white'
                  title      = {i18n.t('equipment_usage.used_by_name_date', { user: `${amenity.usage.user.first_name} ${amenity.usage.user.last_name}`, date: new Date(amenity.usage.start).toLocaleDateString(i18n.locale)})}
                />
                <div>
                  {amenity.usage.permissions.can_take_over_usage &&
                  <Tag
                    icon           = {<FontAwesomeIcon icon="hand-holding-hand" />}
                    tooltipContent = {i18n.t('equipment_usage.actions.take_over')}
                    hover          = 'var(--rep-primary-light)'
                    color          = 'var(--rep-primary)'
                    click          = {() => showModal({
                      title:   i18n.t('equipment_usage.actions.take_over'),
                      content: <StartUsageForm amenity={amenity} usage={amenity.usage} callback={takeUsage} />
                    })}
                  />
                  }
                  {amenity.usage.permissions.can_stop_usage &&
                  <Tag
                    icon           = {<FontAwesomeIcon icon="stop-circle" />}
                    tooltipContent = {i18n.t('equipment_usage.stop')}
                    hover          = 'var(--rep-danger-light)'
                    color          = 'var(--rep-danger)'
                    click          = {() => showModal({
                      title:   i18n.t('equipment_usage.stop'),
                      content: <StopUsageForm usage={amenity.usage} stopUsage={stopUsage} />
                    })}
                  />
                  }
                </div>
              </>
              : <>
                {amenity.permissions.can_start_usage &&
                  <Button
                    icon           = {<FontAwesomeIcon icon="play-circle" />}
                    hover          = 'var(--rep-success-light)'
                    color          = 'var(--rep-success)'
                    click          = {() => showModal({
                      title:   i18n.t('equipment_usage.start'),
                      content: <StartUsageForm amenity={amenity} usage={amenity.usage} callback={startUsage} />
                    })}
                  >
                    {i18n.t('equipment_usage.start')}
                  </Button>
                }
              </>
            }
          </InfoStyle.InfoLineContent>
        </InfoStyle.InfoLine>
        }

        {!!amenity.serial_number &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.serial_number')}
          </InfoStyle.InfoLineTitle>
          <Tag
            // icon           = {<FontAwesomeIcon icon="hashtag" />}
            // hover          = 'var(--rep-primary-light)'
            color          = 'var(--rep-neutral-primary)'
            // tooltipContent = {amenity.serial_number}
          >
            {amenity.serial_number}
          </Tag>
        </InfoStyle.InfoLine>
        }


        {!!amenity.status &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.status')}
          </InfoStyle.InfoLineTitle>
          <Tag
            color          = 'var(--rep-neutral-primary)'
            // background     = 'var(--rep-neutral-light)'
            // hover          = 'var(--rep-neutral-primary-light)'
            // tooltipContent = {i18n.t('amenity.status')}
          >
            {amenity.status}
          </Tag>
        </InfoStyle.InfoLine>
        }

        {!!amenity.internal_identifier &&
          <InfoStyle.InfoLine isDesktop={isDesktop}>
            <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
              {i18n.t('amenity.internal_identifier')}
            </InfoStyle.InfoLineTitle>
            <Tag color = 'var(--rep-neutral-primary)'>
              {amenity.internal_identifier}
            </Tag>
          </InfoStyle.InfoLine>
        }

        {!!amenity.quantity &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.quantity')}
          </InfoStyle.InfoLineTitle>
          <Tag
            color = 'var(--rep-neutral-primary)'
          >
            {amenity.quantity}
          </Tag>
        </InfoStyle.InfoLine>
        }

        {!!amenity.installation_date &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.installation_date')}
          </InfoStyle.InfoLineTitle>
          <Tag color = 'var(--rep-neutral-primary)'>
            {new Date(amenity.installation_date).toLocaleDateString(i18n.locale)}
          </Tag>
        </InfoStyle.InfoLine>
        }

        {!!amenity.expiration_date &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.expiration_date')}
          </InfoStyle.InfoLineTitle>
          <Tag color='var(--rep-neutral-primary)'
          >
            {new Date(amenity.expiration_date).toLocaleDateString(i18n.locale)}
          </Tag>
        </InfoStyle.InfoLine>
        }

        {!!amenity.price &&
        <InfoStyle.InfoLine isDesktop={isDesktop}>
          <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
            {i18n.t('amenity.price')}
          </InfoStyle.InfoLineTitle>
          <Tag color='var(--rep-neutral-primary)'>
            {amenity.price}
          </Tag>
        </InfoStyle.InfoLine>
        }

        {!!amenity.custom_fields.length &&
          amenity.custom_fields.map(field =>
            <InfoStyle.InfoLine isDesktop={isDesktop} key={field.id}>
              <InfoStyle.InfoLineTitle isDesktop={isDesktop}>
                {field.name}
              </InfoStyle.InfoLineTitle>
              <FieldTag
                key       = {field.id}
                field     = {field}
                // value     = {report.custom_fields.find(cf => cf.field.id === field.id)?.value}
                renderTag = {false}
              />
            </InfoStyle.InfoLine>
          )
        }
      </Expander>

      <InfoStyle.Subheader>
        <FontAwesomeIcon icon="gauge" /> {i18n.t('measurment.measurments')}
      </InfoStyle.Subheader>
      <div style={{display: 'flex', gap: '8px', alignItems: 'stretch', flexWrap: 'wrap', margin: '8px 0' }}>
        {amenity.measurments.map(measurment =>
          <Measure
            key           = {measurment.id}
            measure       = {measurment}
            updateMethods = {updateMethods}
          />
        )}
        {!readOnly && amenity.permissions.can_create_measurment &&
          <Button
            hover  = 'var(--rep-success-light)'
            color  = 'var(--rep-success)'
            icon   = {<FontAwesomeIcon icon="plus" />}
            click  = {() => showModal({
              title:   i18n.t('measurment.actions.add_measurment'),
              content: <MeasureForm
                equipment     = {amenity}
                updateMethods = {updateMethods}
              />
            })}
          >
            {i18n.t('measurment.actions.add_measurment')}
          </Button>
        }
      </div>

      {!!amenity.reports_count &&
        <Accordion
          onToggle    = {() => fetchOngoingReports(1)}
          onClose     = {resetOngoingReports}
          border      = "transparent"
          header      = {i18n.t('report.ongoing_reports')}
          counter     = {amenity.reports_count}
          colorHeader = 'var(--rep-success)'
          icon        = {<FontAwesomeIcon icon="circle" color="var(--rep-success)" fade />}
          togglable
        >
          {ongoingReports.map(report => <ReportCard key={report.id} report={report} openInfo={!readOnly} fromModal={fromModal} />)}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {ongoingLoading
              ? <FontAwesomeIcon icon="spinner" spin />
              : (ongoingPagination.page !== ongoingPagination.last) &&
            <Button
              click={() => fetchOngoingReports(ongoingPagination.next)}
            >
              {i18n.t('shared.load_more')}
            </Button>
            }
          </div>
        </Accordion>
      }

      <Accordion
        border      = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        header      = {i18n.t('shared.structure')}
        open        = {false}
        icon        = {<FontAwesomeIcon icon="folder-tree" />}
        togglable
      >
        <Tree
          before           = {[...amenity.unit_ancestors, ...amenity.ancestors]}
          current          = {amenity}
          after            = {amenity.children}
          readOnly         = {readOnly}
          fromModal        = {fromModal}
          highlightedItems = {[amenity]}
          showStatus       = {current_company.permissions.can_use_equipment_usages}
          showTickets
        />
      </Accordion>

      <Accordion
        border      = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        header      = {i18n.t('maintenance.maintenance_list')}
        open        = {false}
        counter     = {amenity.maintenances.length.toString()}
        togglable   = {!!amenity.maintenances.length}
        icon        = {amenity.maintenances.find(m => m.late) ? <FontAwesomeIcon icon="triangle-exclamation" color="var(--rep-danger)" /> : <FontAwesomeIcon icon="layer-group" />}
        action      = {!readOnly && <div style={{ display: 'flex', gap: '4px'}}>
          {amenity.permissions.can_create_maintenance &&
            <Button
              icon={<FontAwesomeIcon icon="link" />}
              hover='var(--rep-primary-light)'
              click={() => showModal({
                title:   i18n.t('maintenance.actions.add_equipment_to_plan', {equipment: amenity.name}),
                content: <AddEquipmentToPlan equipment={amenity} addToPlan={addToPlan} />,
              })}
            >
              {i18n.t('maintenance.actions.add_to_plan')}
            </Button>
          }
          {amenity.permissions.can_create_maintenance &&
            <Button
              icon={<FontAwesomeIcon icon="plus" />}
              hover='var(--rep-primary-light)'
              click={() => showModal({
                title:   i18n.t('maintenance.maintenance_plan'),
                content: <MaintenancesContextProvider current_user={current_user}>
                  <PlanForm
                    current_user    = {current_user}
                    current_company = {current_company}
                    item            = {{ amenities: [{...amenity, maintainable_id: amenity.id, maintainable_name: amenity.name, created: true }] }}
                    customCallbacks = {[fetchAmenity, closeModal]}
                    fromRails
                  />
                </MaintenancesContextProvider>,
                asWizard: true
              })}
            >
              {i18n.t('maintenance.actions.create_plan')}
            </Button>
          }
        </div>
        }
      >
        {amenity.maintenances.map(maintenance =>
          <MaintenanceCard key={maintenance.id} initMaintenance={maintenance} />)
        }
      </Accordion>

      <Accordion
        header      = {i18n.t('document.documents')}
        border      = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        open        = {false}
        counter     = {amenity.documents.length}
        togglable   = {!!amenity.documents.length}
        icon        = {<FontAwesomeIcon icon="file-zipper" />}
        action      = {!readOnly && amenity.permissions.can_create_document &&
          <Button
            icon  = {<FontAwesomeIcon icon="plus" />}
            hover = 'var(--rep-primary-light)'
            click = {() => showModal({
              title:   i18n.t('document.attach_file'),
              content: <FileUploader
                updateMethods = {updateMethods}
                objectType    = 'amenity'
                objectId      = {amenity.id}
                multiple
                closeModalAfter
              />
            })}
          >
            {i18n.t('shared.actions.add')}
          </Button>}
      >
        <FileGrid>
          {amenity.documents.map(doc =>
            <Thumbnail
              key           = {doc.id}
              file          = {doc}
              updateMethods = {updateMethods}
              modaleDetails = {doc.permissions.can_update_document}
              closeModalAfter
            />
          )}
        </FileGrid>
      </Accordion>

      <Accordion
        header      = {i18n.t('contract.contracts')}
        open        = {false}
        counter     = {amenity.contracts.length || '0'}
        togglable   = {!!amenity.contracts.length}
        border      = 'var(--rep-neutral-primary-light)'
        colorHeader = 'var(--rep-neutral-primary)'
        icon        = {<FontAwesomeIcon icon="file-contract" />}
        action      = {!readOnly && <div style={{ display: 'flex', gap: '4px'}}>
          {amenity.permissions.can_link_equipment &&
          <Button
            icon  = {<FontAwesomeIcon icon="link" />}
            hover = 'var(--rep-primary-light)'
            click = {() => showModal({
              title:   i18n.t('contract.actions.add_asset_to_existing', { equipment: amenity.name }),
              content: <AddEquipmentToContract equipment={amenity} addToContract={addToContract} />,
            })}
          >
            {i18n.t('contract.actions.link')}
          </Button>
          }
          {amenity.permissions.can_create_contract &&
          <Button
            icon  = {<FontAwesomeIcon icon="plus" />}
            hover = 'var(--rep-primary-light)'
            click = {() => showModal({
              title:   i18n.t('contract.actions.create_a_new'),
              content: <ContractForm
                amenity        = {amenity}
                createContract = {createContract}
              />
            })}
          >
            {i18n.t('contract.actions.create')}
          </Button>
          }
        </div>}
      >
        {amenity.contracts.map(contract =>
          <ContractCard
            key      = {contract.id}
            contract = {contract}
            update   = {updateContract}
            unlink   = {unlinkContract}
          />
        )}
      </Accordion>

      {current_company.permissions.can_see_costs &&
       <Accordion
         border      = 'var(--rep-neutral-primary-light)'
         colorHeader = 'var(--rep-neutral-primary)'
         header      = {i18n.t('offer_line.costs')}
         open        = {false}
         counter     = {`${amenity.costs?.total || '0'} €`}
         togglable
         icon        = {<FontAwesomeIcon icon="sack-dollar" />}
         action      = {
           <WithTooltip content={i18n.t('offer_line.tooltip.evolution_per_equipment')}>
             <FontAwesomeIcon icon="question-circle" />
           </WithTooltip>
         }
       >
         {!!amenity.costs?.total &&
          <Line
            categories = {categories}
            data       = {series}
          />
         }
         <MultiselectItem
           name   = {i18n.t('offer_line.line_types.hours')}
           icon   = {<FontAwesomeIcon icon="user-clock" />}
           border = 'transparent'
         >
           {amenity.costs?.hours} €
         </MultiselectItem>
         <MultiselectItem
           name   = {i18n.t('offer_line.line_types.transport')}
           icon   = {<FontAwesomeIcon icon="truck-moving" />}
           border = 'transparent'
         >
           {amenity.costs?.transport} €
         </MultiselectItem>
         <MultiselectItem
           name   = {i18n.t('offer_line.line_types.external_invoice')}
           icon   = {<FontAwesomeIcon icon="file-invoice-dollar" />}
           border = 'transparent'
         >
           {amenity.costs?.invoices} €
         </MultiselectItem>
         {current_company.permissions.access_stock &&
        <MultiselectItem
          name   = {i18n.t('offer_line.line_types.inventory')}
          icon   = {<FontAwesomeIcon icon="cubes" />}
          border = 'transparent'
        >
          {amenity.costs?.materials} €
        </MultiselectItem>
         }
         <hr />
         <MultiselectItem
           name   = 'Total'
           color  = 'var(--rep-primary)'
           icon   = {<FontAwesomeIcon icon="receipt" />}
           border = 'transparent'
         >
           {amenity.costs?.total} €
         </MultiselectItem>
       </Accordion>
      }
    </>
  )
}

export default InfoAmenity
