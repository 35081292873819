import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@components/Button'
import Input  from '@form/Input'
import Tree   from '@components/Tree'

import { useGlobalContextState } from '@context/GlobalContext'

import IAmenity     from '@interfaces/IAmenity.d'
import IAmenityType from '@interfaces/IAmenityType.d'
import IUnit        from '@interfaces/IUnit.d'

interface SelectEquipmentProps {
  units:              IUnit[]
  amenities:          IAmenity[]
  unitFieldOption:    'mandatory' | 'optional' | 'hidden'
  amenityFieldOption: 'mandatory' | 'optional' | 'hidden'
  amenityTypeFilter:  IAmenityType[]
  showTreeTickets:    boolean
  checkCallback:      (any) => void
  uncheckCallback:    (any) => void
  autoSelectUnit:     boolean
  idKey:              string
  checkboxComponent:  React.ReactNode
  openSelector:       boolean
}

const SelectEquipmentForm: React.FC<SelectEquipmentProps> = ({
  /** Selected units */
  units     = [],
  /** Selected amenities */
  amenities = [],

  /** Should Unit selector be displayed */
  unitFieldOption = 'optional',
  /** Should Amenity selector be displayed*/
  amenityFieldOption = 'optional',

  /** If Amenities should be filtered by amenity types */
  amenityTypeFilter = [],

  /** Show ongoing ticket number in Tree */
  showTreeTickets = false,
  showTreeStatus  = false,

  /** Callback when checkboxing an item */
  checkCallback,
  /** Callback when uncheckboxing an item */
  uncheckCallback,

  /** Auto select the unit when selecting an amenity */
  autoSelectUnit = false,

  /** If identifier is different than id */
  idKey = 'id',
  checkboxComponent,
  openSelector = false,
  closeAfterSelect = false,
}) => {

  const { fetchApi, i18n, current_company } = useGlobalContextState()

  const [showTree,       setShowTree]       = useState((!units.length && !amenities.length) || openSelector)
  const [timeoutId,      setTimeoutId]      = useState()
  const [unitLoading,    setUnitLoading]    = useState(false)
  const [amenityLoading, setAmenityLoading] = useState(false)

  const [search,                setSearch]                = useState('')
  const [usedEquipmentsToggled, setUsedEquipmentsToggled] = useState(false)

  const [unitResults,     setUnitResults]     = useState([])
  const [unitPage,        setUnitPage]        = useState(1)
  const [unitHasNextPage, setUnitHasNextPage] = useState(false)

  const [amenityResults,     setAmenityResults]     = useState([])
  const [amenityPage,        setAmenityPage]        = useState(1)
  const [amenityHasNextPage, setAmenityHasNextPage] = useState(false)

  const fetched = useRef(false)
  useEffect(() => {
    if (fetched.current || !showTree) return
    fetchEquipment({ amenities: unitFieldOption === 'hidden', roots: true })
    fetched.current = true
  }
  , [])

  const fetchEquipment = ({
    page      = 1,
    search    = '',
    units     = true,
    amenities = true,
    roots     = false,
    clear     = false
  }) => {
    if (units && (unitFieldOption !== 'hidden')) {
      setUnitLoading(true)
      fetchApi({
        url:      `/units/rep_search?page=${page}&roots=${roots}&q=${search}&unpaginate=${roots}&reports=true`,
        callback: data => {
          const results = clear ? [] : [...unitResults]
          setUnitPage(data.page)
          setUnitHasNextPage(!!data.next_page)
          setUnitResults([...results, ...data.response.results])
          setUnitLoading(false)
        }
      })
    }

    if (amenityFieldOption === 'hidden') return
    if (amenities) {
      setAmenityLoading(true)
      fetchApi({
        url:      `/amenities/null/rep_tree_search?page=${page}&q=${search}&reports=true&unit=true&path=true&path_string=true&amenity_type_id=${amenityTypeFilter.map(t => t.id).join(',')}`,
        callback: data => {
          const results = clear ? [] : [...amenityResults]
          setAmenityPage(data.page)
          setAmenityHasNextPage(!!data.next_page)
          setAmenityResults([...results, ...data.items])
          setAmenityLoading(false)
        }
      })
    } else {
      setAmenityResults([])
      setAmenityLoading(true)
      setAmenityPage(1)
      setAmenityHasNextPage(false)
      setAmenityLoading(false)
    }
  }

  const toggleSelector = toggle => {
    if (toggle) {
      setShowTree(toggle)
      fetchEquipment({ clear: true, amenities: unitFieldOption === 'hidden', roots: true })
    } else {
      setShowTree(toggle)
      setUnitResults([])
      setAmenityResults([])
    }
  }

  const check = (item, checked) => {
    if (checked) {
      if (item.class_plural === 'amenities') {
        autoSelectUnit && (checkCallback && checkCallback(item.unit))
      }
      checkCallback && checkCallback(item)
      if (closeAfterSelect) toggleSelector(false)
    } else {
      uncheckCallback && uncheckCallback(item)
    }
  }

  useEffect(() => {
    if (usedEquipmentsToggled) usedEquipments()
    else fetchEquipment({ roots: true, amenities: unitFieldOption === 'hidden', clear: true })
  }, [usedEquipmentsToggled])

  const usedEquipments = () => {
    setAmenityLoading(true)
    setUnitResults([])
    fetchApi({
      url:      `/amenities/rep_search?unpaginated=true&unit=true&path=true&path_string=true&currently_used=true&reports=true&amenity_type_id=${amenityTypeFilter.map(t => t.id).join(',')}`,
      callback: data => {
        const results = []
        setAmenityPage(1)
        setAmenityHasNextPage(false)
        setAmenityResults([...results, ...data.response.results])
        setAmenityLoading(false)
      }
    })
  }

  const highlightedItems = useMemo(() => {
    let items = units.concat(amenities)
    items.forEach(item => {
      item?.path
        ? items = [...items, ...item.path]
        : null
    })
    return items
  }, [units, amenities])


  useEffect(() => searchCallback(), [search])
  const searchCallback = () => {
    if (!search) {
      if (timeoutId) { clearTimeout(timeoutId) }
      setTimeoutId(setTimeout(() => fetchEquipment({ clear: true, units: true, amenities: unitFieldOption === 'hidden', roots: true }), 300))
    } else {
      if (timeoutId) { clearTimeout(timeoutId) }
      setTimeoutId(setTimeout(() => fetchEquipment({ search, clear: true, units: true, amenities: !!search }), 300))
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Button
          icon    = {<FontAwesomeIcon icon={showTree ? 'caret-down' : 'caret-right'} />}
          hover   = 'var(--rep-primary-light)'
          click   = {() => toggleSelector(!showTree)}
          marginY = 'M'
        >
          {i18n.t('amenity.actions.select_equipment')}
        </Button>

        {showTree &&
        <>
          {current_company.permissions.can_use_equipment_usages && amenityFieldOption !== 'hidden' &&
          <Button
            icon  = {<FontAwesomeIcon icon={usedEquipmentsToggled ? 'times' : 'user-gear'} />}
            hover = {'var(--rep-primary-light)'}
            border= {usedEquipmentsToggled ? 'var(--rep-primary)' : null}
            click = {() => setUsedEquipmentsToggled(toggled => !toggled)}
          >
            {i18n.t('equipment_usage.currently_used_amenities')}
          </Button>
          }
          <div style={{ display: 'flex', gap: '4px'}}>
            <Input
              icon        = {<FontAwesomeIcon icon="magnifying-glass" color='var(--rep-neutral-middle)'/>}
              defaultValue= {search}
              change      = {e => setSearch(e.currentTarget.value)}
              // enterKey    = {e => setSearch(e.currentTarget.value)}
              placeholder = {i18n.t('shared.search')}
              width       = '100%'
              marginY     = 'S'
              size        = 'S'
              style       = {{ flexGrow: 1 }}
            />
            <Button
              icon  = {<FontAwesomeIcon icon="times" />}
              click = {() => setSearch('')}
            />
          </div>
        </>
        }
      </div>

      {showTree && unitFieldOption !== 'hidden' &&
      <>
        {unitLoading &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px', color: 'var(--rep-neutral-primary)', margin: '4px 0'}}>
          <FontAwesomeIcon icon="spinner" spin /> {i18n.t('shared.loading_units')}
        </div>
        }
        {!!unitResults.length &&
          unitResults.map(root =>
            <Tree
              key                = {`${root.class_plural}-${root.id}`}
              current            = {root}
              checkCallback      = {check}
              highlightedItems   = {highlightedItems}
              highlightedIdKey   = {idKey}
              unitFieldOption    = {unitFieldOption}
              amenityFieldOption = {amenityFieldOption}
              amenityTypeFilter  = {amenityTypeFilter}
              checkboxComponent  = {checkboxComponent}
              showTickets        = {showTreeTickets}
              showStatus         = {showTreeStatus}
              selectable
              fromModal
            />
          )
        }
        {!unitLoading && (unitHasNextPage && !amenityLoading) &&
            <div style={{ display: 'flex', justifyContent: 'center', color: 'var(--rep-neutral-primary)' }}>
              <Button
                color = 'var(--rep-neutral-primary)'
                click = {() => fetchEquipment({ page: unitPage + 1 })}
              >
                {i18n.t('shared.load_more')}
              </Button>
            </div>
        }
      </>
      }


      {showTree && amenityFieldOption !== 'hidden' &&
      <>
        {amenityLoading &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px', color: 'var(--rep-neutral-primary)', margin: '4px 0'}}>
            <FontAwesomeIcon icon="spinner" spin /> {i18n.t('shared.loading_amenities')}
          </div>
        }
        {!!amenityResults.length &&
          amenityResults.map(root =>
            <Tree
              key                = {`${root.class_plural}-${root.id}`}
              current            = {root}
              checkCallback      = {check}
              highlightedItems   = {highlightedItems}
              highlightedIdKey   = {idKey}
              unitFieldOption    = {unitFieldOption}
              amenityFieldOption = {amenityFieldOption}
              amenityTypeFilter  = {amenityTypeFilter}
              checkboxComponent  = {checkboxComponent}
              showTickets        = {showTreeTickets}
              showStatus         = {showTreeStatus}
              selectable
              fromModal
            />
          )
        }
        {!amenityLoading && (amenityHasNextPage && !unitLoading) &&
          <div style={{ display: 'flex', justifyContent: 'center', color: 'var(--rep-neutral-primary)' }}>
            <Button
              color = 'var(--rep-neutral-primary)'
              click = {() => fetchEquipment({ page: amenityPage + 1 })}
            >
              {i18n.t('shared.load_more')}
            </Button>
          </div>
        }
      </>
      }

      {showTree && (!unitLoading && !amenityLoading) && (!unitResults.length && !amenityResults.length) &&
        <div style={{ display: 'flex', justifyContent: 'center', color: 'var(--rep-neutral-primary)', margin: '8px 0', background: 'var(--rep-neutral-light)', padding: '4px', borderRadius: '4px'}}>
          {i18n.t('shared.no_result')}
        </div>
      }

      {showTree && <div style={{ display: 'flex', justifyContent: 'flex-end'}}><Button
        icon    = {<FontAwesomeIcon icon='times' />}
        // hover   = 'var(--rep-primary-light)'
        click   = {() => toggleSelector(!showTree)}
        marginY = 'M'
      >
        {i18n.t('shared.actions.hide_selector')}
      </Button>
      </div>
      }


    </>
  )
}

export default SelectEquipmentForm
